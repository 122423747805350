import React, { useContext, useRef, useState } from 'react';
import { Button, Checkbox, Comment, CommentAuthor, CommentAvatar, CommentContent, CommentGroup, CommentMetadata, CommentText, Embed, Form, FormInput, FormTextArea, Grid, Header, Icon, Image, Label, Message, Modal, Table } from 'semantic-ui-react';
import { getLengthString, loadingShow, normalizeStringURLIMG } from '../../myfunctions';
import { useToasts } from 'react-toast-notifications';
import AuthContext from '../../context/authContext';
import { useEffect } from 'react';
import { ConnectWS, ConnectWSSendData } from '../../ConnectWS';
import LogoTeamLigaGenerator1 from '../../assets/logotgver1.svg';
import { Markup } from 'interweave';
import { FileUpload } from 'primereact/fileupload';
import { locale, addLocale } from 'primereact/api';
import { confirmAlert } from 'react-confirm-alert';
import useAuth from '../../hooks/useAuth';
import useCheckUserIsDemo from '../../hooks/useCheckUserIsDemo';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function SupportPage({ offInfo }) {
  locale('pl');
  addLocale('pl', {
    choose: "Wybierz",
    upload: "Wgraj",
    cancel: "Anuluj",
    pending: "Oczekuje",
    completed: "Wysyłany"
  });
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [loading, setloading] = useState(false)
  const [open, setOpen] = useState(false)
  const [openFile, setOpenFile] = useState(false)
  const [rowData, setrowData] = useState([])
  const [chckbskip_closed_tickets, setchckbskip_closed_tickets] = useState(true)
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  const isDemo = useCheckUserIsDemo()
  const [blobDataFile, setblobDataFile] = useState(null)
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars


  useEffect(() => {
    if (auth) {
      getTickets(chckbskip_closed_tickets)
    }

    return () => {

    }; // use effect cleanup to set flag false, if unmounted
  }, [auth, chckbskip_closed_tickets])// eslint-disable-line react-hooks/exhaustive-deps

  function getIdT(id) {
    return `TG-${String(id).padStart(6, '0')}`
  }

  function getStatusT(statusValue) {
    try {
      if (statusValue.toLowerCase() === "new") {
        return <Label size='small' color='blue'>NOWE</Label>
      } else if (statusValue.toLowerCase() === "in progress") {
        return <Label size='small' color='green'>W REALIZACJI</Label>
      } else if (statusValue.toLowerCase() === "closed") {
        return <Label size='small' color='grey'>ZAMKNIĘTE</Label>
      } else {
        return <Label size='small' color='purple'>{statusValue}</Label>
      }
    } catch (e) {
      return <Label size='small' color='black'>?</Label>
    }

  }

  function getTickets(chckbvalue) {
    setloading(true)
    ConnectWS("/ticket/getTickets", authContext.token, { skip_closed_tickets: chckbvalue }, function (response, logout) {
      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {
        if (response.data) {
          setrowData(response.data)
        }
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }
      setloading(false)

    });
  }


  // DODANIE TICKETA START
  const [loadingADDT, setloadingADDT] = useState(false)
  const [dataNewTicket, setdataNewTicket] = useState({
    topic: "",
    ticket_body: ""
  })


  const addNewTicket = async e => {
    e.preventDefault();
    setloadingADDT(true)
    ConnectWS("/ticket/addTicket", authContext.token, { ...dataNewTicket }, function (response, logout) {
      if (logout === 1) {
        addToast("Brak zalogowania", { appearance: 'info' });
        setloadingADDT(false)
      } else if (response && response.status && Number(response.status) === 200) {

        setloadingADDT(false)
        addToast(response.data.message, { appearance: 'success' });
        setdataNewTicket({
          topic: "",
          ticket_body: ""
        })
        setOpen(false)
        getTickets(chckbskip_closed_tickets)

      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        setloadingADDT(false)
      }

    });
  }

  function closeTicket(ticket_id) {
    confirmAlert({
      title: 'Proszę potwierdzić',
      message: 'Czy chcesz zamknąć zgłoszenie?',
      buttons: [
        {
          label: 'Tak',
          onClick: () => closeTicketACC(ticket_id)
        },
        {
          label: 'Nie',
          onClick: () => ''
        }
      ]
    });
    function closeTicketACC(ticket_id) {
      ConnectWS("/ticket/closeTicket", authContext.token, { ticket_id: ticket_id }, function (response, logout) {
        if (logout === 1) {
          addToast("Brak zalogowania", { appearance: 'info' });
        } else if (response && response.status && Number(response.status) === 200) {

          addToast(response.data.message, { appearance: 'success' });
          getTickets(chckbskip_closed_tickets)

        } else {
          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }
        }

      });
    }
  }
  // DODANIE TICKETA END

  // WIDOK TICKETA START
  const [vewTicketID, setvewTicketID] = useState(0)
  const [loadingGetT, setloadingGetT] = useState(false)
  const [replayBody, setreplayBody] = useState(null)
  const [rowTicketBody, setrowTicketBody] = useState(null)
  const [loadingART, setloadingART] = useState(false)

  const addReplayTicket = async e => {
    e.preventDefault();
    setloadingART(true)
    ConnectWS("/ticket/addTicketMessage", authContext.token, { ticket_id: vewTicketID, message_body: replayBody }, function (response, logout) {
      if (logout === 1) {
        addToast("Brak zalogowania", { appearance: 'info' });
      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
        getTicketById(vewTicketID)
        setreplayBody(null)
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }
      setloadingART(false)

    });
  }

  function getPrefixF() {
    try {
      return new Date().toJSON().slice(0, 19).replaceAll('T', '-').replaceAll(':', '-')
    } catch (e) {
      return "2000-01-01"
    }
  }

  function getTicketById(tid) {
    setloadingGetT(true)
    setvewTicketID(tid)
    ConnectWS("/ticket/getTicketById", authContext.token, { ticket_id: tid }, function (response, logout) {
      if (logout === 1) {

      } else if (response && response.status && Number(response.status) === 200) {
        if (response.data) {
          setrowTicketBody(response.data)
        }
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }
      setloadingGetT(false)

    });
  }

  // WIDOK TICKETA END


  // upload file

  const ref = useRef(null)
  const customBase64Uploader = async (event) => {
    if (event.files.length > 0) {
      let arrFileOK = []
      let arrFileErr = []
      let currCF = 1
      event.files.forEach((row) => {
        callbackPhoto(row, currCF, event.files.length, arrFileOK, arrFileErr)
        currCF++
      })
    }

    function callbackPhoto(nfile, currCF, count, arrFileOK, arrFileErr) {


      var formData = new FormData();
      formData.append('fileData', nfile, getPrefixF() + "_" + normalizeStringURLIMG(nfile.name));
      formData.append('ticket_id', vewTicketID);

      ConnectWSSendData("/ticket/addAttachmentToTicket", authContext.token, formData, function (response, logout) {
        if (logout === 1) {
          arrFileErr.push(nfile)
        } else if (response && response.status && Number(response.status) === 200) {
          arrFileOK.push(nfile)

        } else {
          arrFileErr.push(nfile)
          if (logout === 101) {
            addToast(response, { appearance: 'info' });
          } else if (logout === 102) {
            addToast(response, { appearance: 'warning' });
          } else {
            addToast(response, { appearance: 'error' });
          }

        }
        try {
          if (currCF === count) {
            ref.current.setFiles(arrFileErr);
            ref.current.setUploadedFiles(arrFileOK);
          }
          getTicketById(vewTicketID)
        } catch (e) {
          console.log(e)
        }

      });

    }

  }
  // upload file end


  function getAttachment(url, name) {

    const authHeader = "Bearer " + authContext.token

    const options = {
      headers: {
        Authorization: authHeader
      }
    };

    fetch(url, options)
      .then(res => res.blob())
      .then(blob => {
        var url = window.URL.createObjectURL(blob);

        if (width < 1000 && !name.match(/\.(jpg|jpeg|png|gif)$/i)) {
          var a = document.createElement('a');
          a.href = url;
          a.download = name;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove();  //afterwards we remove the element again    
        } else {
          setblobDataFile(<Embed
            active={true}
            url={url}
          />)
          setOpenFile(true)
        }

      });


  }
  return (
    loading || loadingGetT ? loadingShow() : vewTicketID > 0 ? <>
      <Button onClick={() => {
        setvewTicketID(0)
        setreplayBody(null)
        getTickets(chckbskip_closed_tickets)
      }} size='mini'>POWRÓT DO LISTY ZGŁOSZEŃ</Button>
      <div style={{ marginTop: 20 }}>
        {rowTicketBody ? <>
          <Grid>
            <Grid.Row >
              <Grid.Column width={3}>
                <b>Numer zgłoszenia</b><br /> {getIdT(rowTicketBody.ticket_id)}
              </Grid.Column>
              <Grid.Column width={3}>
                <b>Data zgłoszenia</b><br /> {rowTicketBody.dt}
              </Grid.Column>
              <Grid.Column width={7}>
                <b>Nazwa zgłoszenia</b><br /> {rowTicketBody.topic}
              </Grid.Column>
              <Grid.Column width={3} textAlign='right'>
                <b>Status</b><br /> {getStatusT(rowTicketBody.status)}
              </Grid.Column>

            </Grid.Row>
          </Grid>

          {rowTicketBody && rowTicketBody.messages ?
            <CommentGroup>
              <Header as='h3' dividing>
                Zgłoszenie
              </Header>
              {rowTicketBody.messages.map((row, i) =>
                <Comment key={i}>
                  <CommentAvatar style={{ opacity: row.user_id === 0 ? 1 : 0.3 }} src={row.user_id === 0 ? '/img/tg.png' : '/img/nouser.png'} />
                  <CommentContent>
                    <CommentAuthor >{row.user_name}</CommentAuthor>
                    <CommentMetadata style={{ marginLeft: 0 }}>
                      <div><Icon name="calendar alternate" />{row.dt}</div>
                    </CommentMetadata>
                    <CommentText style={{ backgroundColor: "#f3f4f6", borderRadius: 5, padding: "10px 10px 10px 10px", marginTop: 10 }}>
                      <Markup content={row.body} />
                      {row.attachment && row.attachment.url ? <div style={{ marginTop: 10 }}><span className='tllink cursorPointer' onClick={() => getAttachment(row.attachment.url, row.attachment.file_name)}><Icon name="linkify" />{row.attachment.file_name}</span></div> : ""}
                    </CommentText>

                  </CommentContent>
                </Comment>
              )}
              {rowTicketBody.status && rowTicketBody.status.toLowerCase() === "closed" ? "" :
                <Form style={{ marginTop: 30 }} onSubmit={addReplayTicket}>
                  <FormTextArea label="ODPOWIEDŹ" required style={{ minHeight: 300 }} value={replayBody} onChange={(e, d) => setreplayBody(d.value)} />
                  <div className="card" style={{ marginBottom: 10 }}>
                    <FileUpload ref={ref} accept="application/pdf,image/*" multiple maxFileSize={1000000} customUpload uploadHandler={customBase64Uploader} emptyTemplate={<p className="m-0">Drag and drop.</p>} />
                  </div>
                  <Button loading={loadingART} disabled={loadingART} content='Dodaj odpowiedź' labelPosition='left' icon='edit' primary />
                </Form>}
            </CommentGroup>
            : ""}

        </> : "BRAK DANYCH"}
      </div>
      <Modal
        open={openFile}
        size='large'
        basic={true}
      >
        <Modal.Content style={{ textAlign: "center" }}>
          {blobDataFile}
        </Modal.Content>
        <Modal.Actions >
          <Button color='black' onClick={() => setOpenFile(false)}>
            ZAMKNIJ
          </Button>

        </Modal.Actions>
      </Modal>
    </>
      :
      <>
        {offInfo === true ? <div style={{ marginTop: 20 }}></div> :
          <Message size='mini'>Jeżeli masz jakiekolwiek pytania, wątpliwości lub zauważysz problem lub błąd z generatorem, zachęcamy do skorzystania z formularza zgłoszeniowego. Jesteśmy tu, aby Ci pomóc i postaramy się odpowiedzieć tak szybko, jak to możliwe. </Message>
        }
        <Button size='mini' color='blue' style={{ marginBottom: 10 }} onClick={() => isDemo ? addToast("Zablokowane na koncie testowym, prosze się zalogować na swoje konto.", { appearance: 'warning' }) : setOpen(true)}>DODAJ NOWE ZGŁOSZENIE/PYTANIE</Button>
        <Checkbox toggle checked={chckbskip_closed_tickets} onClick={(e, d) => setchckbskip_closed_tickets(d.checked)} style={{ marginLeft: 10 }} label="Ukryj zamknięte zgłoszenia" />
        <div style={{ overflowX: "auto", width: "100%" }} >
          <Table size='small' celled striped compact unstackable >
            <Table.Header >
              <Table.Row>
                <Table.HeaderCell width={1} textAlign='center' singleLine>LP</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='center' singleLine>NR ZGŁOSZENIA</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='center' singleLine>ZGŁASZAJĄCY</Table.HeaderCell>

                <Table.HeaderCell width={5} textAlign='center' >NAZWA</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='center' singleLine>DATA DODANIA</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='center' singleLine>STATUS</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='center' singleLine>OSTATNIA ODP</Table.HeaderCell>

                <Table.HeaderCell width={1} textAlign='center' singleLine></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rowData.length > 0 ?
                rowData.map((line, i) =>
                  <Table.Row key={i}>
                    <Table.Cell textAlign='center' singleLine>{i + 1}</Table.Cell>
                    <Table.Cell textAlign='center' singleLine><Button icon labelPosition='left' onClick={() => getTicketById(line.ticket_id)} size='mini'> <Icon color='blue' name='eye' /> {getIdT(line.ticket_id)}</Button></Table.Cell>
                    <Table.Cell textAlign='center' singleLine>{line.request_user_name}</Table.Cell>
                    <Table.Cell textAlign='center'>{line.topic}</Table.Cell>

                    <Table.Cell textAlign='center' singleLine>{line.ticket_dt}</Table.Cell>
                    <Table.Cell textAlign='center' singleLine>{getStatusT(line.status)}</Table.Cell>
                    <Table.Cell textAlign='center' singleLine>{line.last_response_user_name}
                      <br /><span style={{ fontSize: 12 }}>{line.last_reponse_dt}</span>
                    </Table.Cell>

                    <Table.Cell textAlign='center'>
                      {line.status && line.status.toLowerCase() === "closed" ? "" : <Button icon labelPosition='left' onClick={() => closeTicket(line.ticket_id)} size='mini'> <Icon name='close' /> ZAMKNIJ</Button>}
                    </Table.Cell>
                  </Table.Row>

                ) : <Table.Row >
                  <Table.Cell textAlign='center' singleLine colSpan={8}>LISTA TWOICH ZGŁOSZEŃ JEST PUSTA</Table.Cell>
                </Table.Row>}
            </Table.Body>
          </Table>
        </div>
        <Modal
          open={open}

          size='large'
        >
          <Modal.Header>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={12} verticalAlign="middle">
                  <Header as='h3' textAlign='center'>
                    <Icon name='mail' color="red" />
                    <Header.Content>DODAJ NOWE ZGŁOSZENIE/PYTANIE</Header.Content>
                  </Header>
                </Grid.Column>
                <Grid.Column width={4} textAlign="right" only='large screen'>
                  <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator1} />
                </Grid.Column>
              </Grid.Row>
            </Grid>

          </Modal.Header>
          <Modal.Content style={{ backgroundColor: "#f5f5f5" }}>
            <Form onSubmit={addNewTicket}>
              <FormInput required fluid value={dataNewTicket.topic} onChange={(e, d) => getLengthString(d.value,90,false) ? "" : setdataNewTicket({ ...dataNewTicket, topic: d.value })} label={'Nazwa zgłoszenia - pozostało '+getLengthString(dataNewTicket.topic,90,true)+" znaków"} placeholder='Nazwa' />
             
              <FormTextArea required style={{ minHeight: 300 }} value={dataNewTicket.ticket_body} onChange={(e, d) => getLengthString(d.value,2000,false) ? "" : setdataNewTicket({ ...dataNewTicket, ticket_body: d.value })} label={'Opis zgłoszenia - pozostało '+getLengthString(dataNewTicket.ticket_body,2000,true)+" znaków"} placeholder='Opis zgłoszenia' />
              <Button color='blue'>DODAJ ZGŁOSZENIE</Button>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color='black' loading={loadingADDT} disabled={loadingADDT} onClick={() => setOpen(false)}>
              ZAMKNIJ
            </Button>

          </Modal.Actions>
        </Modal>


      </>


  )
}

export default SupportPage;