import React, { useEffect, useState, useRef, useContext } from 'react';

import { Icon, Header, Divider, Segment, Form, Grid, Button, Input, Image, Select, Card, Label, Message, Checkbox, Container, StepGroup, Step, StepTitle, StepContent, StepDescription, MessageHeader, MessageList } from 'semantic-ui-react'
//import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { ConnectWS } from '../../../../ConnectWS'
import AuthContext from '../../../../context/authContext';
import { useToasts } from 'react-toast-notifications';

import useAuth from '../../../../hooks/useAuth';

import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { changeFormatDateTimeDB, exportLocaleRPC, getCyklPackage, getLimitUsersPackage, packageColor, removeEmojis, removeSpecialChars } from '../../../../myfunctions'
import "react-datepicker/dist/react-datepicker.css";
import tgtl from '../../../../assets/gtl.png';
import cali6 from '../../../../assets/partners/6cali.png';
import useCheckUserIsDemo from '../../../../hooks/useCheckUserIsDemo';
import GoogleSuggest from '../../../../tools/google/GoogleSuggest';
import CountDownTimer from '../../countDownTimer/CountDownTimer';
import { addLocale } from 'primereact/api';
import GetEditorTG from '../../../../tools/GetEditorTG';
import AddImgMediaUser from '../../../../tools/AddImgMediaUser';
import MyImageLazy from '../../../../tools/MyImageLazy';
import InputCalendarAndTime from '../../../../tools/InputCalendarAndTime';


function AddTournamentStep(props) {
  const { addToast } = useToasts();
  const [getUserTokenStatsV, setgetUserTokenStatsV] = useState();
  const authContext = useContext(AuthContext);
  const [rte, setrte] = useState("");
  const [loading, setloading] = useState(false);
  const [ttestcheckbox, setttestcheckbox] = useState(false);// eslint-disable-line no-unused-vars
  const [TournamentTypePlay, setTournamentTypePlay] = useState([]);
  const [imgUrlTG, setimgUrlTG] = useState(null);
  const [checbExternalTSend, setchecbExternalTSend] = useState(true);

  addLocale('pl', exportLocaleRPC());

  const isDemo = useCheckUserIsDemo()
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars



  function getUserTokenStats() {


    ConnectWS("/generator/getUserTokenStats", authContext.token, {}, function (response, logout) {

      if (logout === 1) {


      } else if (response && response.status && Number(response.status) === 200) {
        setgetUserTokenStatsV(response.data)
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }


      }


    });
  }


  function getTournamentTypePlay() {
    //setloading(true)
    ConnectWS("/generator/getPointsCalcSystemTypes", null, {}, function (response, logout) {
      if (logout === 1) {
      } else if (response && response.status && Number(response.status) === 200) {

        let arr = []
        arr.length = 0
        response.data.forEach((data, i) => {
          arr.push({ key: i, text: data.system_name, value: data.points_calc_system_id })
          //dictemat.push({ key: i, text: data.Login, value: data.Login })
        })

        setTournamentTypePlay(arr);

      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
      }
      // setloading(false)
    });
  }






  const fileInputRefCategory = useRef(null)

  let date = new Date();
  const [form, setForm] = useState({
    tournamentName: '',
    organizer_name: '',
    organizer_phone: '',
    organizer_email: '',
    InputValuePlace: { value: '', data: null },
    tournamentUrl: '',
    tournamentCategories: [],
    tournamentTypeGames: "",
    tournamentTypeGamesVariant: null,
    dateFrom: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 3),
    dateTo: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 4, 23, 59, 59),
    registerto: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 3),
    description: "",
    tournamentImg: "",
    registrationOnline: false,
    memberType: "USER",
    isTournamentPackage: authContext.user.subscription_info.subscription_active && authContext.user.subscription_info.subscription_extended >= 0 ? 2 : 0,
    tournament_type_play: 0,
  });



  useEffect(() => {
    getTournamentTypePlay()
    getUserTokenStats()
    if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
      console.log("View: AddTournament")
    }

    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (isDemo) {
      setForm({ ...form, isTournamentPackage: 2 })
    }


    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [isDemo])// eslint-disable-line react-hooks/exhaustive-deps


  function getTournamentType(dateFrom, dateTo) {
    try {
      let Difference_In_Time = dateTo.getTime() - dateFrom.getTime();
      let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
      if (Difference_In_Days >= 6) {
        return "LEAGUE"
      } else {
        return "CUP_SIMPLE"
      }

    } catch (e) {
      console.log(e)
      return "CUP_SIMPLE"
    }
  }

  const submitAddTournament = async e => {
    e.preventDefault();
   
    if(imgUrlTG!==null && imgUrlTG!=="" && imgUrlTG.length>750){
      addToast("Proszę poprawić url do zdjęcia, wydaje się nieprawidłowy przekracza 750 znaków!", { appearance: 'warning' });
      addToast("Zweryfikuj czy link nie zawiera jakiś dodatkowych parametrów lub skorzystaj z przycisku MULTIMEDIA", { appearance: 'info' });
      return
    }
    

    if (form.tournamentCategories.length < 1) {
      addToast("Proszę dodać przynajmniej jedną kategorię", { appearance: 'warning' });
      return
    }

    if (form.tournament_type_play < 1) {
      addToast("Proszę wybrać rodzaj sportu", { appearance: 'warning' });
      return
    }

    if (form.InputValuePlace.place_id === null || typeof (form.InputValuePlace.place_id) === 'undefined') {
      addToast("Proszę uzupełnić prawidłowo lokalizację", { appearance: 'warning' });
      return
    }

    /*
    const formData = new FormData();
    formData.append('img_file', imgUrlTG);
    formData.append('tournament_name', ttestcheckbox ? form.tournamentName + " - (TESTOWY)" : form.tournamentName);
    formData.append('organizer_name', form.organizer_name);
    formData.append('organizer_phone', form.organizer_phone);
    formData.append('organizer_email', form.organizer_email);
    formData.append('place', JSON.stringify(form.InputValuePlace));
    formData.append('url', form.tournamentUrl);
    formData.append('tournament_categories', JSON.stringify(form.tournamentCategories));
    formData.append('member_type', form.memberType);
    formData.append('tournament_description', rte);
    formData.append('tournament_type_games', form.tournamentTypeGames);
    formData.append('tournament_type_games_variant', form.tournamentTypeGamesVariant);
    formData.append('start_date', changeFormatDateTimeDB(form.dateFrom));
    formData.append('stop_date', changeFormatDateTimeDB(form.dateTo));
    formData.append('register_stop_date', changeFormatDateTimeDB(form.registerto));
    formData.append('register_online', form.registrationOnline);
    formData.append('tournament_free', form.isTournamentPackage);
    formData.append('points_calc_system_id', form.tournament_type_play ? form.tournament_type_play : 2);
    formData.append('tournament_elo', 1);
    formData.append('send_to_external_service', checbExternalTSend ? true : false);
*/
    let jsonData = {
      img_file: imgUrlTG,
      tournament_name: ttestcheckbox ? form.tournamentName.trim() + " - (TESTOWY)" : form.tournamentName.trim(),
      organizer_name: form.organizer_name,
      organizer_phone: form.organizer_phone,
      organizer_email: form.organizer_email,
      place: JSON.stringify(form.InputValuePlace),
      url: form.tournamentUrl,
      tournament_categories: JSON.stringify(form.tournamentCategories),
      member_type: form.memberType,
      tournament_description: rte,
      tournament_type_games: getTournamentType(form.dateFrom, form.dateTo), // sprawdzenie czy ligowy
      tournament_type_games_variant: form.tournamentTypeGamesVariant,
      start_date: changeFormatDateTimeDB(form.dateFrom),
      stop_date: changeFormatDateTimeDB(form.dateTo),
      register_stop_date: changeFormatDateTimeDB(form.registerto),
      register_online: form.registrationOnline,
      tournament_free: form.isTournamentPackage,
      points_calc_system_id: form.tournament_type_play ? form.tournament_type_play : 2,
      tournament_elo: 1,
      send_to_external_service: checbExternalTSend ? true : false
    }
    
    setloading(true)
    ConnectWS("/generator/createNewTournament", authContext.token, { ...jsonData }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {
        // setmodalStatus({ value: response.data.message, error: false, success: true })
        // getUserTeams()
        // authContext.changeLeagueAction(authContext.leagueAction + 1)
        // addToast(response.data.message, { appearance: 'success' });
        addToast(response.data.message, { appearance: 'success' });
        authContext.changeView({ myView: "MyTournaments" });

      } else {
        // setmodalStatus({ value: response, error: true, success: false })
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }
      setloading(false)


    });

  }






  function addCategoryT() {
    if (form.isTournamentPackage === 0) {
      if (form.tournamentCategories.length >= Number(process.env.REACT_APP_IN_LIMITBROWNCAT)) {
        addToast('Nie masz zaznaczonej opcji turnieju w pakiecie srebrnym, złotym, diamentowym, diamentowym plus! Dlatego jest ograniczenie tylko do ' + Number(process.env.REACT_APP_IN_LIMITBROWNCAT) + ' kategorii.', { appearance: 'warning' });
        return
      }
    }

    let arrtmp = form.tournamentCategories
    if (fileInputRefCategory.current && fileInputRefCategory.current.value.toUpperCase().trim()) {
      let index = arrtmp.indexOf(removeSpecialChars(removeEmojis((fileInputRefCategory.current.value.toUpperCase().trim()))))
      if (index !== -1) {
        addToast('Taka kategoria jest juz dodana!', { appearance: 'warning' });
      } else {
        arrtmp.push(removeSpecialChars(removeEmojis((fileInputRefCategory.current.value.toUpperCase().trim()))))
        setForm({ ...form, tournamentCategories: arrtmp })
      }


    } else {
      addToast('Proszę wpisać nazwę kategorii', { appearance: 'warning' });
    }
  }

  function delCategoryT(line) {
    let array = [...form.tournamentCategories]; // make a separate copy of the array
    let index = array.indexOf(line)
    if (index !== -1) {
      array.splice(index, 1);
      setForm({ ...form, tournamentCategories: array })
    }
  }

  function unlockTournamenFree(data) {

    if (data === 0) {
      if (form.tournamentCategories.length > Number(process.env.REACT_APP_IN_LIMITBROWNCAT)) {
        addToast('Masz więcej niż ' + Number(process.env.REACT_APP_IN_LIMITBROWNCAT) + ' kategorie!', { appearance: 'warning' });
        return
      }
    } else {
      if (getUserTokenStatsV && getUserTokenStatsV.currentTokensAmount < 1) {
        addToast('Brak TOKENÓW-GTL na Twoim koncie', { appearance: 'error' });
        return
      }

    }

    setForm({ ...form, isTournamentPackage: data, registrationOnline: false })
  }


  function setTurnamentNamet(tname) {
    if (tname.toLowerCase().includes("test")) {
      setchecbExternalTSend(false)
    }
    setForm({ ...form, tournamentName: removeSpecialChars(removeEmojis((tname))) })
  }

  const [step, setstep] = useState(0)
  function nexStep(val) {

    if (step + val < 0) {
      return
    } else if (3 - 1 < step + val) {
      return
    }
    if (step === 0 && checkStep(step)) {
      setstep(step + val)
    } else if (step === 1 && checkStep(step)) {
      setstep(step + val)
    } else if (step === 2 && checkStep(step)) {
      setstep(step + val)
    } else {
      return
    }


  }

  const [showErr, setshowErr] = useState([])
  function checkStep(val) {
    let ret = true
    let errArr = []

    if (val === 0) {
      if (form.tournamentName === null || form.tournamentName === '') {
        errArr.push('Wpisz nazwę turnieju')
        ret = false
      } else {
      }
      if (form.tournament_type_play === null || form.tournament_type_play === 0) {
        errArr.push('Wybierz rodzaj turnieju')
        ret = false
      } else {
      }
      if (form.InputValuePlace.data === null || form.InputValuePlace.data === '') {
        errArr.push('Wybierz miejsc turnieju')
        ret = false
      } else {
      }
      if (form.organizer_name === null || form.organizer_name === '') {
        errArr.push('Wpisz organizatora')
        ret = false
      } else {
      }

    }
    if (val === 1) {
      if (form.tournamentCategories.length < 1) {
        errArr.push('Dodaj minimum 1 kategorię')
        ret = false
      } else {
      }
    }
    setshowErr(errArr)
    return ret

  }


  return (
    <div style={{ maxWidth: 1000, margin: "0 auto" }}> <Header as='h4' textAlign='center'>
      <Icon name='add' />
      <Header.Content>Dodaj turniej lub ligę</Header.Content>
    </Header>

      <Divider />

      <StepGroup fluid ordered size='mini'>
        <Step completed={step !== 0 ? true : false} active={step === 1 ? true : false}>
          <StepContent>
            <StepTitle>Informacje</StepTitle>
            <StepDescription style={{ margin: 0, padding: 0 }}>Wymagane</StepDescription>
          </StepContent>
        </Step>

        <Step completed={step !== 1 ? true : false} active={step === 2 ? true : false}>
          <StepContent>
            <StepTitle>Kategorie</StepTitle>
            <StepDescription style={{ margin: 0, padding: 0 }}>Turniejowe, ligowe</StepDescription>
          </StepContent>
        </Step>

        <Step completed={step !== 2 ? true : false} active={step === 3 ? true : false}>
          <StepContent>
            <StepTitle>Informacje</StepTitle>
            <StepDescription style={{ margin: 0, padding: 0 }}>Dodatkowe</StepDescription>
          </StepContent>
        </Step>
      </StepGroup>
      <Button.Group widths='3' size='mini'>
        <Button onClick={() => nexStep(-1)} size='mini' color="blue" labelPosition='left' icon='arrow circle left' content='Poprzedni krok' />
        <Button.Or />
        <Button onClick={() => nexStep(+1)} size='mini' color="blue" labelPosition='right' icon='arrow circle right' content='Następny krok' />
      </Button.Group>
      <Segment style={{ backgroundColor: "#f5f5f5" }}>
        {showErr.length > 0 ?
          <Message size='mini' error>
            <MessageHeader>Popraw formularz</MessageHeader>
            <MessageList items={showErr} />

          </Message>
          : ""}
        <Form onSubmit={submitAddTournament}  >

          {step === 0 ?

            <>
              <Form.Field
                control={Input}
                value={form.tournamentName}
                onChange={(event, data) => setTurnamentNamet(removeSpecialChars(removeEmojis(data.value)))}
                label='Nazwa rozgrywek'
                placeholder='Nazwa rozgrywek'
                required={true}

              />
              <Form.Field
                control={Select} selectOnBlur={false}
                options={TournamentTypePlay}
                value={form.tournament_type_play}
                onChange={(event, data) => {

                  setForm({ ...form, tournament_type_play: data.value })
                }}
                label='Rodzaj sportu'
                search
                required={true}
                placeholder='Proszę wybrać...'

              />
              {form.tournament_type_play === 2 || form.tournament_type_play === 17 ?
                <Message info>
                  <Grid className='mp0'>
                    <Grid.Row className='mp0'>
                      <Grid.Column width={3} >
                        <Image src={cali6} size='small' alt="6cali" />
                      </Grid.Column>
                      <Grid.Column width={13} >
                        <p>
                          Drogi użytkowniku,<br />
                          Z radością informujemy, że turniej zostanie dodatkowo utworzony na portalu 6cali.pl, naszym cenionym partnerze.<br /> Link do turnieju na stronie partnera będzie widoczny w naszym panelu po utworzeniu turnieju.
                        </p>
                        <Checkbox style={{ fontSize: 12 }} checked={checbExternalTSend} onChange={(e, d) => setchecbExternalTSend(d.checked)} label='Opublikuj turniej na powyższym portalu.' />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                </Message>
                : ""
              }
              <Form.Group widths={'equal'}>
                <Form.Field >
                  <label>Start rozgrywek</label>
                  {/* 
                  <DatePicker
                    locale="pl"
                    selected={form.dateFrom}
                    onChange={(date) => setForm({ ...form, dateFrom: date, registerto: date })}
                    timeInputLabel="Godzina:"
                    dateFormat="yyyy-MM-dd HH:mm "
                    showTimeInput
                    showPopperArrow={false}
                  />*/}
                  <InputCalendarAndTime form={form} setForm={setForm} inputValue={"dateFrom"} />
                </Form.Field>
                <Form.Field>
                  <label>Koniec rozgrywek</label>
                  {/* 
                  <DatePicker
                    locale="pl"
                    selected={form.dateTo}
                    onChange={(date) => setForm({ ...form, dateTo: date })}
                    timeInputLabel="Godzina:"
                    dateFormat="yyyy-MM-dd HH:mm "
                    showTimeInput
                    showPopperArrow={false}
                  />
                  */}
                  <InputCalendarAndTime form={form} setForm={setForm} inputValue={"dateTo"} />
                </Form.Field>
              </Form.Group>
              <GoogleSuggest form={form} setForm={setForm} />
              <Form.Field
                control={Input}
                value={form.organizer_name}
                onChange={(event, data) => setForm({ ...form, organizer_name: removeSpecialChars(removeEmojis((data.value))) })}
                label='Organizator'
                placeholder='Organizator'
                required={true}
              />
            </>
            : ""}

          {step === 1 ?
            <>
              <Form.Group >
                <Form.Field width={15}>
                  <label>Kategorie turniejowe lub ligowe</label>
                  <input ref={fileInputRefCategory} style={{ textTransform: "uppercase" }} placeholder='Wpisz nazwę kategorii i kliknij dodaj' />
                </Form.Field>
                <Form.Field width={1}>
                  <label>Dodaj</label>
                  <Button
                    size="tiny"
                    icon="add"
                    type="button"
                    onClick={() => addCategoryT()}
                  />
                </Form.Field>
              </Form.Group>
              <strong>Twoje dodane kategorie <span style={{ color: "red" }}>*</span></strong>
              <Segment>
                {form.tournamentCategories.length > 0 ? form.tournamentCategories.map((line, i) =>


                  <Form.Group key={i}>
                    <Form.Field >
                      <label style={{ marginTop: 10 }}>{i + 1}.</label>
                    </Form.Field>
                    <Form.Field  >
                      <input size="big" value={line} readOnly />
                    </Form.Field>
                    <Form.Field >
                      <Button
                        icon="minus"
                        type="button"
                        onClick={() => delCategoryT(line)}
                      />
                    </Form.Field>
                  </Form.Group>

                ) : "Brak dodanych kategorii"}
              </Segment>

            </>
            : ""}

          {step === 2 ? <>



            <Form.Field
              control={Input}
              type='tel'
              pattern="[0-9]{9}"
              value={form.organizer_phone}
              onChange={(event, data) => setForm({ ...form, organizer_phone: (data.value) })}
              label='Kontakt tel do organizatora (format 123456789) - 9 cyfr'
              placeholder='123456789'
              required={false}
            />
            <Form.Field
              control={Input}
              type='email'
              value={form.organizer_email}
              onChange={(event, data) => setForm({ ...form, organizer_email: (data.value) })}
              label='Kontakt email do organizatora'
              placeholder='@email'
              required={false}
            />


            <Form.Field
              control={Input}
              value={form.tournamentUrl}
              onChange={(event, data) => setForm({ ...form, tournamentUrl: data.value })}
              label='Link do strony rozgrywek'
              placeholder='Posiadasz własną stronę z informacją na temat rozgrywek? dodaj do niej link.'
              type="url"
            />





            <label><strong>Opis</strong></label>

            <GetEditorTG rte={rte} setrte={setrte} />

            <Divider />
            <Form.Group widths='equal'>
              {imgUrlTG && imgUrlTG !== "" ? <MyImageLazy openView={true} maxHeight={100} src={imgUrlTG} /> : ""}
              <Form.Field
                control={Input}
                value={imgUrlTG}
                type='url'
                onChange={(event, data) => setimgUrlTG(data.value)}
                label='Link URL do plakatu/zdjęcia'
                placeholder='Link URL do plakatu/zdjęcia'


              />
              <AddImgMediaUser imgUrlTG={imgUrlTG} setimgUrlTG={setimgUrlTG} />

            </Form.Group>
            <Divider />
            {/* 
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={10}>
                  <label>{form.tournamentImg ? form.tournamentImg.name : "Brak wybranego pliku"}</label>{form.tournamentImg ? <Icon name="trash" onClick={clearImageChange} /> : ""}
                  <br />
                  <Button
                    content="Wybierz plik graficzny/plakat"
                    labelPosition="left"
                    icon="file"
                    type="button"
                    onClick={() => fileInputRef.current.click()}
                  /><input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={onImageChange}
                  />

                </Grid.Column>
                <Grid.Column width={5}>
                  {image ? <Image src={image} alt="zdjecie" size='big' onClick={() => openModal(image)} style={{ cursor: 'pointer' }} /> : ""}

                </Grid.Column>
              </Grid.Row>
            </Grid>
*/}



            <Form.Group inline >
              <label>Uczestnicy biorący udział w rozgrywkach to:</label>
              <Form.Radio
                label='Osoby'
                value='USER'
                checked={form.memberType === 'USER'}
                onChange={(event, data) => setForm({ ...form, memberType: data.value })}
              />
              <Form.Radio
                label='Drużyny'
                value='TEAM'
                checked={form.memberType === 'TEAM'}
                onChange={(event, data) => setForm({ ...form, memberType: data.value })}
              />
            </Form.Group>


            <Form.Group inline>
              <Form.Checkbox onChange={(e, data) => setForm({ ...form, registrationOnline: data.checked })} disabled={form.isTournamentPackage === 0 ? true : false} checked={form.registrationOnline} inline /> Właczyć zapisy online?
              <label></label>

              <label>Koniec zapisów</label>
              {/* 
              <DatePicker
                locale="pl"
                selected={form.registerto}
                onChange={(date) => setForm({ ...form, registerto: date })}
                timeInputLabel="Godzina:"
                dateFormat="yyyy-MM-dd HH:mm "
                showTimeInput
                showPopperArrow={false}
                disabled={form.registrationOnline === false}
              />*/}
              <InputCalendarAndTime form={form} setForm={setForm} inputValue={"registerto"} />
            </Form.Group>
            <Button style={{ marginTop: 17 }} color='red' loading={loading} disabled={loading} type='submit' fluid>ZATWIERDŹ DODAWANE I PRZEJDŹ DO WYBORU SYSTEMU TURNIEJOWEGO LUB LIGOWEGO</Button>
          </> : ""}





        </Form>
      </Segment>

      <Segment>

        {authContext.user.subscription_info.subscription_active && authContext.user.subscription_info.subscription_extended >= 0 ? <>
          <div style={{ padding: 10, background: "linear-gradient(80deg, rgba(219, 181, 245,1) 0%, rgba(255, 255, 255,1) 100%)" }}>
            {authContext.user.subscription_info && authContext.user.subscription_info.subscription_extended === 1 ? packageColor(5) : packageColor(4)} Pakiet Diamentowy{authContext.user.subscription_info && authContext.user.subscription_info.subscription_extended === 1 ? " Plus," : ","} subskrypcja aktywna.
          </div>
          <Header style={{ margin: 0, marginTop: 5 }} block>
            <CountDownTimer endDate={authContext.user.subscription_info.subscription_to} textValue="DO KOŃCA SUBSKRYPCJI" textValueFinish="" beautifulText="0" />
          </Header>
        </>
          : <>
            <Divider horizontal style={{ margin: 0, padding: 0 }}>
              <Header style={{ fontSize: 14 }}>
                <Icon name='certificate' />
                PAKIETY
              </Header>
            </Divider>

            <Card.Group centered style={{ margin: 2 }}>
              <Card color={form.isTournamentPackage === 0 ? 'green' : "grey"} className={form.isTournamentPackage === 0 ? 'colorSegmentCardDarkSukcesy1' : "colorSegmentCardDarkSukcesy0"}>
                <Card.Content >
                  <Card.Header style={{ marginBottom: 10 }}> {packageColor(1)} PAKIET BRĄZOWY</Card.Header>
                  <Container textAlign='center' style={{ fontSize: 12, lineHeight: "18px" }}>
                    <b>LIMIT UCZESTNIKÓW - {getLimitUsersPackage(0)}</b> <Icon className='cursorPointer' name="question circle" onClick={() => authContext.changeModalInfo({ nameModal: "PackagesGTL", name: "Pakiety - Cennik", value: "" })} />
                    <br />
                    <b>LIMIT KATEGORII - {process.env.REACT_APP_IN_LIMITBROWNCAT}</b> <Icon className='cursorPointer' name="question circle" onClick={() => authContext.changeModalInfo({ nameModal: "PackagesGTL", name: "Pakiety - Cennik", value: "" })} />
                    <Divider className='mp3' />
                    <Checkbox onChange={(e, data) => unlockTournamenFree(0)} checked={form.isTournamentPackage === 0 ? true : false} label="WYBIERAM" />
                  </Container>
                </Card.Content>
                <Card.Content extra>

                </Card.Content>
                <Label attached='bottom'>PAKIET DARMOWY</Label>
              </Card>
              <Card style={{ opacity: getUserTokenStatsV && getUserTokenStatsV.currentTokensAmount < 1 ? "0.5" : 1 }} color={form.isTournamentPackage === 1 ? 'green' : "grey"} className={form.isTournamentPackage === 1 ? 'colorSegmentCardDarkSukcesy1' : "colorSegmentCardDarkSukcesy0"}>
                <Card.Content >
                  <Card.Header style={{ marginBottom: 10 }}> {packageColor(2)} PAKIET SREBRNY</Card.Header>
                  <Container textAlign='center' style={{ fontSize: 12, lineHeight: "18px" }}>
                    <b>LIMIT UCZESTNIKÓW - {getLimitUsersPackage(1)} (per kategoria)</b> <Icon className='cursorPointer' name="question circle" onClick={() => authContext.changeModalInfo({ nameModal: "PackagesGTL", name: "Pakiety - Cennik", value: "" })} />
                    <br />
                    <b>LIMIT KATEGORII - BRAK</b> <Icon className='cursorPointer' name="question circle" onClick={() => authContext.changeModalInfo({ nameModal: "PackagesGTL", name: "Pakiety - Cennik", value: "" })} />
                    <Divider className='mp3' />
                    <Checkbox disabled={getUserTokenStatsV && getUserTokenStatsV.currentTokensAmount < 1 ? true : false} onChange={(e, data) => unlockTournamenFree(1)} checked={form.isTournamentPackage === 1 ? true : false} label={getUserTokenStatsV && getUserTokenStatsV.currentTokensAmount < 1 ? "BRAK TOKENTÓW!" : "WYBIERAM"} />
                  </Container>
                </Card.Content>
                <Card.Content extra>

                </Card.Content>
                <Label attached='bottom'>ZOSTANIE POBRANE: 1 GTL</Label>
              </Card>
              <Card style={{ opacity: getUserTokenStatsV && getUserTokenStatsV.currentTokensAmount < 2 ? "0.5" : 1 }} color={form.isTournamentPackage === 2 ? 'green' : "grey"} className={form.isTournamentPackage === 2 ? 'colorSegmentCardDarkSukcesy1' : "colorSegmentCardDarkSukcesy0"}>
                <Card.Content >
                  <Card.Header style={{ marginBottom: 10 }}> {packageColor(3)} PAKIET ZŁOTY</Card.Header>
                  <Container textAlign='center' style={{ fontSize: 12, lineHeight: "18px" }}>
                    <b>LIMIT UCZESTNIKÓW - BRAK</b> <Icon className='cursorPointer' name="question circle" onClick={() => authContext.changeModalInfo({ nameModal: "PackagesGTL", name: "Pakiety - Cennik", value: "" })} />
                    <br />
                    <b>LIMIT KATEGORII - BRAK</b> <Icon className='cursorPointer' name="question circle" onClick={() => authContext.changeModalInfo({ nameModal: "PackagesGTL", name: "Pakiety - Cennik", value: "" })} />
                    <Divider className='mp3' />
                    <Checkbox disabled={getUserTokenStatsV && getUserTokenStatsV.currentTokensAmount < 2 ? true : false} onChange={(e, data) => unlockTournamenFree(2)} checked={form.isTournamentPackage === 2 ? true : false} label={getUserTokenStatsV && getUserTokenStatsV.currentTokensAmount < 2 ? "BRAK TOKENTÓW!" : "WYBIERAM"} />

                  </Container>
                </Card.Content>
                <Card.Content extra>

                </Card.Content>
                <Label attached='bottom'>ZOSTANIE POBRANE: 2 GTL</Label>
              </Card>
            </Card.Group>
            <Divider style={{ margin: 0, padding: 0 }} />
            <Grid stackable verticalAlign='middle'>
              <Grid.Row >
                <Grid.Column width={11}>
                  {getCyklPackage()}
                </Grid.Column>
                <Grid.Column width={5}>
                  <Card.Group centered style={{ margin: 2 }}>
                    <Card className='colorSegmentCardDarkSukcesy0'>

                      <Card.Content >
                        <Image
                          style={{ width: 70 }}
                          floated='right'
                          alt="logo"
                          src={tgtl}
                        />
                        <Card.Header>TOKEN-GTL</Card.Header>
                        <Card.Meta style={{ fontSize: 14, lineHeight: 1.1 }}> Poniżej znajduje się liczba Twoich tokentów</Card.Meta>

                      </Card.Content>
                      <Card.Content extra>

                      </Card.Content>
                      <Label attached='bottom'>POSIADASZ: <span style={{ fontSize: 18, color: getUserTokenStatsV && getUserTokenStatsV.currentTokensAmount > 1 ? "green" : getUserTokenStatsV && getUserTokenStatsV.currentTokensAmount > 0 ? "orange" : "red" }}>{getUserTokenStatsV ? getUserTokenStatsV.currentTokensAmount : "?"}</span> GTL</Label>
                    </Card>
                  </Card.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>}
      </Segment>


    </div>
  )
}



export default AddTournamentStep;