import { t } from 'i18next';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useState, useEffect } from 'react';
import { Icon, Image, Input, Table } from 'semantic-ui-react';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Accordion as AccordionRP, AccordionTab } from 'primereact/accordion';
import ShowImgAvatar from '../ShowImgAvatar';
import { getlinkplayerprofile,  mobileWSmall, returnIconStats } from '../../myfunctions';


function ShowStatsExTournament({ statsExTournament, subtournament_id, isDarkTheme }) {

    const [membersStatsAll, setmembersStatsAll] = useState({ column: null, data: [], direction: null, filterData: null })
    const [membersAndTeams, setmembersAndTeams] = useState([])
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars


    useEffect(() => {

        if (statsExTournament && statsExTournament.length > 0) {
            statsExTournament.forEach(element => {
                if (element.stid === subtournament_id && element.dataMembers.length > 0) {
                    setmembersStatsAll({ ...membersStatsAll, data: element.dataMembers, filterData: membersStatsAll.filterData && membersStatsAll.filterData.length > 0 ? membersStatsAll.filterData : element.dataMembers })
                    let ttt = element.dataMembers
                    if (ttt && ttt.length > 0) {
                        ttt = ttt.sort((a, b) => b.team_name - a.team_name || b.member_name - a.member_name)
                        //ttt = Object.groupBy(ttt, ({ team_name }) => team_name); to rozwala się na IPHONE!
                        ttt = ttt.reduce((x, y) => {
                            (x[y.team_name] = x[y.team_name] || []).push(y);
                            return x;
                        }, {});
                        setmembersAndTeams(ttt)
                    }
                }
            });
        }

        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [statsExTournament, subtournament_id])// eslint-disable-line react-hooks/exhaustive-deps


    function clickSortTable(column, index) {

        let directiontmp = membersStatsAll.direction === 'ascending' ? 'descending' : 'ascending'
        if (directiontmp === "ascending") {
            setmembersStatsAll({ column: column, data: membersStatsAll.data.sort(function (a, b) { return a.stats[index].value - b.stats[index].value }), direction: directiontmp, filterData: membersStatsAll.filterData ? membersStatsAll.filterData.sort(function (a, b) { return a.stats[index].value - b.stats[index].value }) : membersStatsAll.data.sort(function (a, b) { return a.stats[index].value - b.stats[index].value }) })
        } else if (directiontmp === "descending") {
            setmembersStatsAll({ column: column, data: membersStatsAll.data.sort(function (a, b) { return b.stats[index].value - a.stats[index].value }), direction: directiontmp, filterData: membersStatsAll.filterData ? membersStatsAll.filterData.sort(function (a, b) { return b.stats[index].value - a.stats[index].value }) : membersStatsAll.data.sort(function (a, b) { return b.stats[index].value - a.stats[index].value }) })
        }

    }

    function searchTableDataStats(value) {
        try {
            if (value && value.length >= 3) {
                if (membersStatsAll.data) {
                    let tt = []
                    membersStatsAll.data.forEach((row) => {
                        if (row.member_name.toLocaleUpperCase().includes(value.toLocaleUpperCase()) || row.team_name.toLocaleUpperCase().includes(value.toLocaleUpperCase())) {
                            tt.push(row)
                        }
                    })
                    setmembersStatsAll({ ...membersStatsAll, filterData: tt })
                } else {
                    setmembersStatsAll({ ...membersStatsAll, filterData: membersStatsAll.data })
                }
            } else {
                setmembersStatsAll({ ...membersStatsAll, filterData: membersStatsAll.data })
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        membersStatsAll && membersStatsAll.data.length > 0 ?
            <TabView scrollable >
                {membersAndTeams ?
                    <TabPanel header={"ZAWODNICY"} leftIcon={<span className='colorIconTabMenu'><Icon name="users" /></span>} >
                        <div style={{ marginTop: 15 }}>
                            <AccordionRP>
                                {Object.keys(membersAndTeams).map((key, i) => (
                                    <AccordionTab header={key}>

                                        <div style={{ padding: 5, overflowX: "auto", width: "100%" }} >
                                            <Table className='stickyf2c' style={{ marginTop: 5, fontSize: 13, lineHeight: "11px", marginBottom: 2 }} size='small'
                                                inverted={isDarkTheme} celled striped compact unstackable sortable >
                                                <Table.Header >

                                                    <Table.Row>


                                                        <Table.HeaderCell width={1} textAlign='center'></Table.HeaderCell>
                                                        <Table.HeaderCell textAlign='center'>{t("WidokTurnieju.zawodnik")}</Table.HeaderCell>
                                                        {
                                                            membersAndTeams[key][0].stats.map((line, i) =>
                                                                <Table.HeaderCell textAlign='center'>{returnIconStats(line.newkey)}{t("stats." + line.newkey).toLocaleUpperCase()}</Table.HeaderCell>
                                                            )}

                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {
                                                        membersAndTeams[key].map((line, i) =>
                                                            <Table.Row key={i}>
                                                                <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                                                                <Table.Cell textAlign='center' singleLine><figure className='imgPlayer'><ShowImgAvatar urlpic={line.member_img} mtype={"USER"} /><figcaption>{getlinkplayerprofile(line.member_id, line.member_name, 1, width < mobileWSmall() ? 13 : 14, "_blank")}</figcaption></figure></Table.Cell>

                                                                {line.stats.map((line1, i) =>
                                                                    line1.key === 'matche_cards' && 1==2 ? <Table.Cell width={2} textAlign='center'>
                                                                        <div style={{ width: 120, margin: "0 auto" }}>
                                                                            <div style={{ display: "inline-block" }}><Image style={{ height: 20 }} spaced src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/yellowc.png"} />{line1.value.yellow ? line1.value.yellow : 0}</div>
                                                                            <div style={{ display: "inline-block" }}><Image style={{ height: 20 }} spaced src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/cardsyr.png"} />{line1.value.yr ? line1.value.yr : 0}</div>
                                                                            <div style={{ display: "inline-block" }}><Image style={{ height: 20 }} spaced src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/redc.png"} />{line1.value.red ? line1.value.red : 0}</div>
                                                                        </div>
                                                                    </Table.Cell> : <Table.Cell width={1} textAlign='center'>{line1.value}</Table.Cell>

                                                                )}



                                                            </Table.Row>

                                                        )}

                                                </Table.Body>
                                            </Table>


                                        </div>

                                    </AccordionTab>


                                ))}
                            </AccordionRP>
                        </div>
                    </TabPanel>
                    : ""}
                <TabPanel header={t("WidokTurnieju.statystyki_zawodnikow").toLocaleUpperCase()} leftIcon={<span className='colorIconTabMenu'><Icon name="chart area" /></span>} >
                    <div >
                        <Input style={{ marginTop: 10, marginLeft: 5 }} placeholder='Szukaj... min 3 znaki' onChange={(e, d) => searchTableDataStats(d.value)} />
                        <div style={{ padding: 5, overflowX: "auto", width: "100%" }} >
                            <Table className='stickyf2c' style={{ marginTop: 5, fontSize: 13, lineHeight: "11px", marginBottom: 2 }} size='small'
                                inverted={isDarkTheme} celled striped compact unstackable sortable >
                                <Table.Header >

                                    <Table.Row>


                                        <Table.HeaderCell width={1} textAlign='center'></Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>{t("WidokTurnieju.zawodnik")}</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center'>{t("WidokTurnieju.druzyna")}</Table.HeaderCell>
                                        {
                                            membersStatsAll.data[0].stats.map((line, i) =>
                                                <Table.HeaderCell sorted={membersStatsAll.column === line.key ? membersStatsAll.direction : null}
                                                    onClick={() => clickSortTable(line.key, i)} textAlign='center'>{returnIconStats(line.newkey)}{t("stats." + line.newkey).toLocaleUpperCase()}</Table.HeaderCell>
                                            )}

                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {
                                        membersStatsAll.filterData.map((line, i) =>
                                            <Table.Row key={i}>
                                                <Table.Cell textAlign='center'>{i + 1}</Table.Cell>
                                                <Table.Cell textAlign='center' singleLine><figure className='imgPlayer'><ShowImgAvatar urlpic={line.member_img} mtype={"USER"} /><figcaption>{getlinkplayerprofile(line.member_id, line.member_name, 1, width < mobileWSmall() ? 13 : 14, "_blank")}</figcaption></figure></Table.Cell>
                                                <Table.Cell textAlign='center' singleLine><figure className='imgPlayer'><ShowImgAvatar urlpic={line.team_img} mtype={"TEAM"} /><figcaption>{getlinkplayerprofile(line.team_id, line.team_name, 1, width < mobileWSmall() ? 13 : 14, "_blank")}</figcaption></figure></Table.Cell>

                                                {line.stats.map((line1, i) =>
                                                    line1.key === 'matche_cards' && 1==2 ? <Table.Cell width={2} textAlign='center'>
                                                        <div style={{ width: 120, margin: "0 auto" }}>
                                                            <div style={{ display: "inline-block" }}><Image style={{ height: 20 }} spaced src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/yellowc.png"} />{line1.value.yellow ? line1.value.yellow : 0}</div>
                                                            <div style={{ display: "inline-block" }}><Image style={{ height: 20 }} spaced src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/cardsyr.png"} />{line1.value.yr ? line1.value.yr : 0}</div>
                                                            <div style={{ display: "inline-block" }}><Image style={{ height: 20 }} spaced src={process.env.REACT_APP_PUBLIC_URL + "/img/icon/redc.png"} />{line1.value.red ? line1.value.red : 0}</div>
                                                        </div>
                                                    </Table.Cell> : <Table.Cell width={1} textAlign='center'>{line1.value}</Table.Cell>

                                                )}



                                            </Table.Row>

                                        )}

                                </Table.Body>
                            </Table>
                        </div>
                    </div>
                </TabPanel>

            </TabView >
            : ""

    )
}

export default ShowStatsExTournament;