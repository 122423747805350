import { Calendar } from 'primereact/calendar';
import React, { useEffect, useState } from 'react';
import { Grid, Icon } from 'semantic-ui-react';
import { changeFormatDateTimeBS } from '../myfunctions';

function InputCalendarAndTime({ form, setForm, inputValue, returnString }) {

    const [mdata, setmdata] = useState(form[inputValue]);

    useEffect(() => {   
 
        if (form[inputValue] instanceof Date) {
            setmdata(form[inputValue])
        } else {
            setmdata(form[inputValue] ? new Date(form[inputValue]) : new Date())
        }

        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [form[inputValue]])// eslint-disable-line react-hooks/exhaustive-deps


    function saveDataDB(value, co) {

        try {
            if (co === 1) {
                // trzeba pobrać czas ustaiwony i złozyc z nowa data
                let tmpDate = value
                tmpDate.setHours(mdata.getHours())
                tmpDate.setMinutes(mdata.getMinutes())
                tmpDate.setSeconds(mdata.getSeconds())
                setForm({ ...form, [inputValue]: returnString ? changeFormatDateTimeBS(tmpDate) : tmpDate })
            } else {
                // trzeba pobrać czas ustaiwony i złozyc z nowa data
                let tmpDate = value
                tmpDate.setDate(mdata.getDate())
                tmpDate.setMonth(mdata.getMonth())
                tmpDate.setFullYear(mdata.getFullYear())
                setForm({ ...form, [inputValue]: returnString ? changeFormatDateTimeBS(value) : value })
            }
        } catch (e) {
            console.log(e)
            setForm({ ...form, [inputValue]: returnString ? changeFormatDateTimeBS(value) : value })
        }
    }

    return (

        <Grid style={{ margin: 0, padding: 0, marginBottom: 5 }}>
            <Grid.Row columns={2} style={{ margin: 0, padding: 0 }}>
                <Grid.Column style={{ margin: 0, paddingRight: 3, paddingLeft: 0 }}>
                    <Calendar showButtonBar dateFormat="yy-mm-dd" value={form[inputValue] instanceof Date ? form[inputValue] : form[inputValue] ? new Date(form[inputValue]) : new Date()} showIcon locale="pl" onChange={(e) => e.value !== null ? saveDataDB(e.value, 1) : ""} icon={() => <Icon name="calendar alternate" />} />
                </Grid.Column>
                <Grid.Column style={{ margin: 0, paddingRight: 0, paddingLeft: 3 }}>
                    <Calendar value={form[inputValue] instanceof Date ? form[inputValue] : form[inputValue] ? new Date(form[inputValue]) : new Date()} onChange={(e) => e.value !== null ? saveDataDB(e.value, 2) : ""} timeOnly showIcon icon={() => <Icon name="clock" />} />
                </Grid.Column>
            </Grid.Row>
        </Grid>

    )
}

export default InputCalendarAndTime;