import React, { useState, useEffect, useContext, useRef, createRef } from 'react';
import { Header, Modal, Container, Button, Form, Input, Message, Select, Grid, Checkbox, Icon, Image, Segment, Sticky } from 'semantic-ui-react'
import { useToasts } from 'react-toast-notifications';
import { BracketGenerator, BracketGame } from 'react-tournament-bracket';
import { ConnectWS } from '../../../ConnectWS'
import AuthContext from '../../../context/authContext';
import useAuth from '../../../hooks/useAuth';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { blockEditScore, checkScoreOK } from '../../../myfunctions';
import BracketGenCurrentMatch from './BracketGenCurrentMatch';
import { confirmAlert } from 'react-confirm-alert';
import GetScoreDetails from './GetScoreDetails';
import ShowScoreDetails from './ShowScoreDetails';
import LogoTeamLigaGenerator1 from '../../../assets/logotgver1.svg';
import { Slider } from 'react-semantic-ui-range';
import ShowImgAvatar from '../../../tools/ShowImgAvatar';
import { useTranslation } from 'react-i18next';
import EditLeagueMatchInfo from '../../../tools/EditLeagueMatchInfo';
import EditLeagueMatchDetailsEx from '../../../tools/EditLeagueMatchDetailsEx';
import GooglePhotosViewer from '../../../tools/GooglePhotosViewer';


function BracketGen(propsRoot) {
    const { addToast } = useToasts();
    const authContext = useContext(AuthContext);
    const [open, setOpen] = useState(false)
    const [openUsersAdd, setOpenUsersAdd] = useState(false)
    const [openData, setDataOpen] = useState()
    const [openDataUsersAdd, setDataOpenUsersAdd] = useState()
    const [jsonBreacket, setjsonBreacket] = useState()
    const [teamHover, setteamHover] = useState()
    const [form, setfrom] = useState({ homeScore: 0, visitorScore: 0, match_details: null, match_place: null })
    const [formUsersAdd, setfromUsersAdd] = useState({ homeUser: -2, visitorUser: -2 })
    const [messageStatus, setmessageStatus] = useState({ state: 0, text: "" })
    const [tournamentId, settournamentId] = useState(0)
    const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
    const [userList, setuserList] = useState([])
    const [cutBracket, setcutBracket] = useState(true)
    const [BlockCut, setBlockCut] = useState(false)
    const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
    const [totalMembers, settotalMembers] = useState(0)
    const [isloading, setisloading] = useState(false)
    const { t } = useTranslation();
    const wrapperRef = useRef(null);

    const [openModalmatch_info, setopenModalmatch_info] = useState({ open: false, row: null,subtournament_id:0 })
    const [openModalmatch_details_ex, setopenModalmatch_details_ex] = useState({ open: false, row: null, onlyView: 1, st: null,subtournament_id:0 })
    const [GooglePhotosViewerStatus, setGooglePhotosViewerStatus] = useState({ url: null, openView: false })

    const [EMPTY_BRACKET, setEMPTY_BRACKET] = useState(false)

    let contextRefZoom = createRef()

    function stopStickyActive() {
        if (width < 900) {
            return false;
        }
        if (propsRoot.isPanelWrappert) {
            if (window.location.hash) {
                if (window.location.hash.includes("showt")) {
                    return true
                } else {
                    return false
                }
            } else {
                // jest panel i domyślnie jest ukryty
                return false
            }
        }

        return true;

    }

    function modifiDataJson() {

        setmessageStatus({ state: 0, text: "" })

        let nmin = 0
        let nmax = 200
        if (form.homeScore === null && form.visitorScore === null) {
            setmessageStatus({ state: 1, text: "Wpisz wynik meczu" })
            return
        }

        if (form.homeScore === null) {
            setmessageStatus({ state: 1, text: "Wpisz wynik dla niebieskiego" })
            return
        } else if (Number(form.homeScore) < nmin || Number(form.homeScore) > nmax) {
            setmessageStatus({ state: 1, text: "Wpisana wartość wyniku musi być z przedziału od " + nmin + " do " + nmax })
            return
        }
        if (form.visitorScore === null) {
            setmessageStatus({ state: 1, text: "Wpisz wynik dla czerwonego" })
            return
        } else if (Number(form.visitorScore) < nmin || Number(form.visitorScore) > nmax) {
            setmessageStatus({ state: 1, text: "Wpisana wartość wyniku musi być z przedziału od " + nmin + " do " + nmax })
            return
        }
        if (Number(form.homeScore) === Number(form.visitorScore)) {
            setmessageStatus({ state: 1, text: "Wynik nie może być remisowy!" })
            return
        }

        if (checkScoreOK(propsRoot.pointsCalcSystemId, form.homeScore, form.visitorScore) === false) {
            setmessageStatus({ state: 1, text: "Błędnie wpisany wynik" })
            return
        }

        //let dataJson = { ...openData, sides: { ...openData.sides, home: { ...openData.sides.home, score: { ...openData.score, score: form.homeScore } }, visitor: { ...openData.sides.visitor, score: { ...openData.score, score: form.visitorScore } } } }
        let dataJsonTMP = {
            id: openData.id,
            match_details: form.match_details ? form.match_details : null,
            sides: {
                home: {
                    team: openData.sides.home.team,
                    score: {
                        score: form.homeScore
                    },
                },
                visitor: {
                    team: openData.sides.visitor.team,
                    score: {
                        score: form.visitorScore
                    },
                }
            }
        }
        setisloading(true)
        ConnectWS("/generator/setMatchScore", authContext.token, { subtournament_id: tournamentId, dataJson: dataJsonTMP, removeScore: false }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                //setDataOpen(dataJson)
                let textt = openData.sides.home.team.name + " [" + form.homeScore + ":" + form.visitorScore + "] " + openData.sides.visitor.team.name
                exitModal()
                addToast('Zapisane: ' + textt, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                setmessageStatus({ state: 1, text: response })
            }
            setisloading(false)



        });

        //setOpen(false); 
        //let textt = openData.sides.home.team.name+" "+form.homeScore+" "+ openData.visitor.home.team.name+" "+form.visitorScore

    }

    function delMatch() {
        let dataJsonTMP = {
            id: openData.id,
            sides: {
                home: {
                    team: { id: null, name: null },
                    score: {
                        score: null
                    },
                },
                visitor: {
                    team: { id: null, name: null },
                    score: {
                        score: null
                    },
                }
            }
        }

        ConnectWS("/generator/setMatchScore", authContext.token, { subtournament_id: tournamentId, dataJson: dataJsonTMP, removeScore: true }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                //setDataOpen(dataJson)
                //let textt = openData.sides.home.team.name + " [" + form.homeScore + ":" + form.visitorScore + "] " + openData.sides.visitor.team.name
                exitModal()
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                setmessageStatus({ state: 1, text: response })
            }



        });
    }





    function loopJsonBprev(jsonTMP, bracketLabeltmp) {
        let jsonRemaining = []

        loopJsonB(jsonTMP, 1)
        function loopJsonB(jsonTMP, counttmp) {
            Object.entries(jsonTMP).forEach(
                ([key1, value1]) => {
                    if (key1 === "sides") {
                        if (value1.home.seed && value1.home.seed.sourceGame) {
                            if (counttmp === 2) {

                                jsonRemaining.push(JSON.parse(JSON.stringify({ ...value1.home.seed.sourceGame, bracketLabel: bracketLabeltmp + " - " + t("gorna_czesc") })))
                                value1.home.seed.sourceGame = null
                            } else {
                                loopJsonB(value1.home.seed.sourceGame, 2)
                            }
                        }
                        if (value1.visitor.seed && value1.visitor.seed.sourceGame) {
                            if (counttmp === 2) {

                                jsonRemaining.push(JSON.parse(JSON.stringify({ ...value1.visitor.seed.sourceGame, bracketLabel: bracketLabeltmp + " - " + t("dolna_czesc") })))
                                value1.visitor.seed.sourceGame = null
                            } else {
                                loopJsonB(value1.visitor.seed.sourceGame, 2)
                            }
                        }
                    }
                }
            );
        }
        jsonRemaining.push(jsonTMP)
        return jsonRemaining
    }


    function analyzeJson(jsonOld) {
        try {
            let jsonNew = JSON.parse(JSON.stringify(jsonOld));
            let jsonGF
            let jsonPF = []
            let jsonFC
            let jsonRemaining = []
            let tmpTM = propsRoot.totalMembers ? propsRoot.totalMembers : 0


            let tmpbracketLabel = ""
            if (tmpTM > 16 && cutBracket === true && BlockCut === false) {

                Object.entries(jsonNew).forEach(
                    ([key, value]) => {

                        if (value.name === "Finał" || value.name === "Finał A" || value.name === "Final A" || value.name === "Final") {
                            jsonGF = JSON.parse(JSON.stringify({ ...value }))
                        } else if (value.name === "Finał B" || value.name === "Final B") {
                            // rozdzielamy finał B na 3 turnieje.
                            tmpbracketLabel = value.bracketLabel
                            jsonPF = loopJsonBprev(JSON.parse(JSON.stringify({ ...value, bracketLabel: value.bracketLabel + " - " + value.name })), value.bracketLabel)
                        } else if (value.name === "Finał C" || value.name === "Final C") {
                            jsonFC = JSON.parse(JSON.stringify({ ...value, bracketLabel: value.bracketLabel + " - " + value.name }))
                        } else {
                            jsonRemaining.push(JSON.parse(JSON.stringify({ ...value })))
                        }
                    }
                );




                let arrJson = []
                arrJson.length = 0
                if (jsonGF) {
                    arrJson.push(jsonGF)
                }
                if (jsonPF.length > 0) {
                    if (tmpTM > 32 && cutBracket === true) {
                        // rozdzielamy finał B na 6 turniejów.
                        let tmp1 = loopJsonBprev(JSON.parse(JSON.stringify({ ...jsonPF[0], bracketLabel: tmpbracketLabel + " - do 1/2 (górna część)" })), tmpbracketLabel)
                        let tmp2 = loopJsonBprev(JSON.parse(JSON.stringify({ ...jsonPF[1], bracketLabel: tmpbracketLabel + " - do 1/2 (dolna część)" })), tmpbracketLabel)

                        arrJson = arrJson.concat(tmp1[0])
                        arrJson = arrJson.concat(tmp1[1])
                        arrJson = arrJson.concat(tmp2[0])
                        arrJson = arrJson.concat(tmp2[1])
                        arrJson = arrJson.concat(tmp1[2])
                        arrJson = arrJson.concat(tmp2[2])
                        arrJson = arrJson.concat(jsonPF[2])
                    } else {
                        arrJson = arrJson.concat(jsonPF)
                    }

                }
                if (jsonFC) {
                    arrJson.push(jsonFC)
                }
                if (jsonRemaining.length > 0) {

                    arrJson = arrJson.concat(jsonRemaining)
                }

                setjsonBreacket(arrJson)
            } else {

                setjsonBreacket(jsonOld)
            }
        } catch (err) {
            console.log(err)
            setjsonBreacket(jsonOld)
        }


    }


    useEffect(() => {// eslint-disable-line react-hooks/exhaustive-deps

        if (propsRoot.remoteJson && propsRoot.ttype !== "LEAGUE") {

            if (propsRoot.ttype && propsRoot.ttype === "CUP_SIMPLE") {
                setBlockCut(true)
            } else {
                setBlockCut(false)
            }

            

            settotalMembers(propsRoot.totalMembers ? propsRoot.totalMembers : 0)
            analyzeJson(propsRoot.remoteJson)
            settournamentId(propsRoot.tournamentId)

        } else {
            console.log("BRAK JSONA")
        }



        return () => {

        }; // use effect cleanup to set flag false, if unmounted
    }, [propsRoot.remoteJson, propsRoot.tournamentId, cutBracket])// eslint-disable-line react-hooks/exhaustive-deps





    function eventShow(props) {

        // otwieramy do zdefiniowania czasu meczu
        //props.game.sides.home.team.id > 0 && props.game.sides.visitor.team.id > 0 &&
        if ( propsRoot.createTournament !== true) {

            setDataOpen(props.game)
            try {
                //console.log(props.game)
                // pusta drabinka, nalezy umozliwić przypisanie uczestników do turnieju 
                if (props.game.sides.home.team.tmp === 1 || props.game.sides.visitor.team.tmp === 1) {
                    if(propsRoot.onlyView === 0){
                        getListUsersForEmptyB(tournamentId)
                    }
                    setEMPTY_BRACKET(true)
                } else {
                    setEMPTY_BRACKET(false)
                }

            } catch (e) {

            }

            setfrom({ homeScore: props.game ? props.game.sides.home.score.score : 0, visitorScore: props.game ? props.game.sides.visitor.score.score : 0, match_details: props.game.match_details, match_place: props.game.match_place })

            setOpen(true);
        } else if (propsRoot.createTournament === true && props.game.sides.home.team.name !== null && props.game.sides.visitor.team.name !== null && props.game.sides.home.team.name.length > 2 && props.game.sides.visitor.team.name.length > 2) {
            setDataOpenUsersAdd(props.game)

            getListUsers(tournamentId)
            setfromUsersAdd({ homeUser: props.game.sides.home.team.id > 0 ? props.game.sides.home.team.id : -2, visitorUser: props.game.sides.visitor.team.id > 0 ? props.game.sides.visitor.team.id : -2 })
            setOpenUsersAdd(true);
        }
        else {

        }
    }

    let GameComponent = (props) => {
        return (

            <BracketGame
                {...props}
                onHoveredTeamIdChange={(hoveredTeamId) => hoveredTeamId !== null ? setteamHover(hoveredTeamId) : ''}
                //onClick={() => propsRoot.onlyView === 1 ? '' : eventShow(props)}
                onClick={() => eventShow(props)}
                hoveredTeamId={teamHover}
            />

        );
    };

    function exitModal() {
        setmessageStatus({ state: 0, text: "" })
        setOpen(false)
        setOpenUsersAdd(false)
    }



    function modifiDataJsonUserACC() {
        /*
        if (formUsersAdd.homeUser === "" ) {
            setmessageStatus({ state: 1, text: "Brak wybranego ustawienia uczestnika" })
            return
        }
        if (formUsersAdd.visitorUser === "") {
            setmessageStatus({ state: 1, text: "Brak wybranego ustawienia uczestnika" })
            return
        }
        */

        // mozna wpisać jednego a drugi zostanie dolosowany!...
    


        let homeUserIdTmp = formUsersAdd.homeUser === -2 ? null : formUsersAdd.homeUser === -1 ? 0 : formUsersAdd.homeUser
        let visitorUserIdTmp = formUsersAdd.visitorUser === -2 ? null : formUsersAdd.visitorUser === -1 ? 0 : formUsersAdd.visitorUser

        /*
        if (homeUserIdTmp === visitorUserIdTmp) {
            setmessageStatus({ state: 1, text: "Wynik nie może być remisowy!" })
            return
        }
        */

        if (checkScoreOK(propsRoot.pointsCalcSystemId, homeUserIdTmp, visitorUserIdTmp) === false) {
            setmessageStatus({ state: 1, text: "Błędnie wpisany wynik" })
            return
        }

        setisloading(true)
        ConnectWS("/generator/setMatchMembers", authContext.token, { sId: openDataUsersAdd.id, subtournament_id: tournamentId, homeUserId: homeUserIdTmp, visitorUserId: visitorUserIdTmp }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                //setDataOpen(dataJson)
                //let textt = openData.sides.home.team.name + " [" + form.homeScore + ":" + form.visitorScore + "] " + openData.sides.visitor.team.name
                exitModal()
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                setmessageStatus({ state: 1, text: response })
            }
            setisloading(false)


        });
    }


    function getListUsers(subtournament_id) {
        ConnectWS("/generator/getUnassignedSubtournamentMembers", authContext.token, { subtournament_id: subtournament_id }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                //setDataOpen(dataJson)
                let dictemat = []
                dictemat.length = 0
                response.data.forEach((data, i) => {
                    dictemat.push({ key: i, text: data.member_name, value: data.member_id })
                    //dictemat.push({ key: i, text: data.Login, value: data.Login })
                })

                setuserList(dictemat);
            } else {
                setmessageStatus({ state: 1, text: response })
            }



        });
    }

    function getListUsersForEmptyB(subtournament_id) {
        setuserList([]);
        ConnectWS("/generator/getGroupsMembers", authContext.token, { bracket_subtournament_id: subtournament_id }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                //setDataOpen(dataJson)
                let dictemat = []
                dictemat.length = 0
                response.data.forEach((data, i) => {
                    dictemat.push({ key: i, text: data.member_name, value: data.tournament_member_id })
                    //dictemat.push({ key: i, text: data.Login, value: data.Login })
                })

                setuserList(dictemat);
            } else {
                setmessageStatus({ state: 1, text: response })
            }





        });
    }


    function replaceTmpMemberInBracket(tmp_tournament_member_id, source_tournament_member_id) {


    
        if (tmp_tournament_member_id < 1 || source_tournament_member_id < 1) {
            setmessageStatus({ state: 1, text: "Brak przypisanego uczestnika " })
            return
        }


        ConnectWS("/generator/replaceTmpMemberInBracket", authContext.token, { source_tournament_member_id: source_tournament_member_id, tmp_tournament_member_id: tmp_tournament_member_id, subtournament_id: tournamentId }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
                setmessageStatus({ state: 0, text: "" })
            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
                setmessageStatus({ state: 1, text: response })
            }
        })

    }

    const modifiDataJsonPre = async e => {
        e.preventDefault();
        modifiDataJson()
    }

    function hide_bracket_names(value,subtournament_id){
        ConnectWS("/generator/setSubtournamentHideBracketNames", authContext.token, {subtournament_id:subtournament_id,hide_bracket_names:value  }, function (response, logout) {

            if (logout === 1) {
                addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                setTimeout(() => {
                    setAuth(false);
                }, 4000);
            } else if (response && response.status && Number(response.status) === 200) {
                addToast(response.data.message, { appearance: 'success' });
                authContext.changeRefreshView(authContext.refreshView + 1)
            } else {
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
            }
        })
    }

    function setthird_place_encounter(third_place_encounter) {
        confirmAlert({
            title: 'Proszę potwierdzić',
            message: third_place_encounter ? 'Czy chcesz dodać pojedynek o 3 miejsce?' : 'Czy chcesz skasować pojedynek o 3 miejsce?',
            buttons: [
                {
                    label: 'Tak',
                    onClick: () => setthird_place_encounterA(third_place_encounter)
                },
                {
                    label: 'Nie',
                    onClick: () => ''
                }
            ]
        });

       

        function setthird_place_encounterA(third_place_encounter) {
            ConnectWS("/generator/setSubtournamentThirdPlaceEncounter", authContext.token, { third_place_encounter: third_place_encounter, subtournament_id: tournamentId }, function (response, logout) {

                if (logout === 1) {
                    addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
                    setTimeout(() => {
                        setAuth(false);
                    }, 4000);
                } else if (response && response.status && Number(response.status) === 200) {
                    addToast(response.data.message, { appearance: 'success' });
                    authContext.changeRefreshView(authContext.refreshView + 1)
                } else {
                    if (logout === 101) {
                        addToast(response, { appearance: 'info' });
                    } else if (logout === 102) {
                        addToast(response, { appearance: 'warning' });
                    } else {
                        addToast(response, { appearance: 'error' });
                    }
                }
            })
        }
    }

    const [zoomVal, setzoomVal] = useState(100)
    function changeZoom(val) {
        if (wrapperRef && wrapperRef.current) {
            setzoomVal(val)
            wrapperRef.current.style.zoom = val + "%";
        }
    }



    function getmatch_info(match_info, line3,onlyView,subtournament_id) {
       
        try {
            const rows = [];
            if (onlyView === 1) {
    
            } else {
                rows.push(<Icon className='cursorPointer hiddenPrint' title="edytuj dane" name="edit" color='orange' onClick={() => setopenModalmatch_info({ open: true, row: {match_info : line3.match_info,subtournament_league_match_id:line3.id,member1_name:openData.sides.home.team.name,member2_name:openData.sides.visitor.team.name} , subtournament_id:subtournament_id })} />)
            }
    
            for (const [key] of Object.entries(match_info).sort((a, b) => a[1].sort - b[1].sort)) {
                if (match_info[key].type === "inputurl" && match_info[key].name === "match_report") {
                    if (match_info[key].value) {
                        
                        if (width < 1000 && match_info[key].value.includes(".pdf")) {
                            rows.push(<span style={{ marginRight: 5 }}>
                                <a className={"tllink"} href={match_info[key].value} target="_blank" rel="noopener noreferrer"><Icon color='green' name={match_info[key].icon} />{t("stats." + match_info[key].name)}</a>
                            </span>)
                        } else {
                            rows.push(<span style={{ marginRight: 5 }} className='cursorPointer' onClick={() => authContext.changeModalInfo({ nameModal: "MatchReport", name: t("stats." + match_info[key].name), value: match_info[key].value })}>
                                <Icon color='green' name={match_info[key].icon} />{t("stats." + match_info[key].name)}
                            </span>)
                        }
    
                    } else if (onlyView === 0) {
                        rows.push(<span style={{ marginRight: 5 }}><Icon color='grey' name={match_info[key].icon} />{t("stats." + match_info[key].name)}</span>)
                    }
                } else if (match_info[key].type === "inputurl") {
                    if (match_info[key].value) {
                        rows.push(<span style={{ marginRight: 5 }}>
                            <a className={"tllink"} href={match_info[key].value} target="_blank" rel="noopener noreferrer"><Icon color='green' name={match_info[key].icon} />{t("stats." + match_info[key].name)}</a>
                        </span>)
                    } else if (onlyView === 0) {
                        rows.push(<span style={{ marginRight: 5 }}><Icon color='grey' name={match_info[key].icon} />{t("stats." + match_info[key].name)}</span>)
                    }
                } else if (match_info[key].type === "inputvideourl") {
                    if (match_info[key].value) {
                        rows.push(<span style={{ marginRight: 5 }} className='cursorPointer' onClick={() => authContext.changeModalInfo({ nameModal: "VideoModal", name: t("stats." + match_info[key].name), value: match_info[key].value })}>
                            <Icon color='green' name={match_info[key].icon} />{t("stats." + match_info[key].name)}
                        </span>)
                    }
                    else if (onlyView === 0) {
                        rows.push(<span style={{ marginRight: 5 }}><Icon color='grey' name={match_info[key].icon} />{t("stats." + match_info[key].name)}</span>)
                    }
                } else if (match_info[key].type === "inputgooglephotourl") {
                    if (match_info[key].value) {
                        rows.push(<span style={{ marginRight: 5 }} className='cursorPointer' onClick={() => setGooglePhotosViewerStatus({ url: match_info[key].value, openView: true })}>
                            <Icon color='green' name={match_info[key].icon} />{t("stats." + match_info[key].name)}
                        </span>)
                    } else if (onlyView === 0) {
                        rows.push(<span style={{ marginRight: 5 }}><Icon color='grey' name={match_info[key].icon} />{t("stats." + match_info[key].name)}</span>)
                    }
                } else if (match_info[key].name === "match_place") {
                    // zablokowane
                } else if (onlyView === 0) {
                    rows.push(<span style={{ marginRight: 5 }}>
                        <Icon color='grey' name={match_info[key].icon} />{t("stats." + match_info[key].name)}: {match_info[key].value ? match_info[key].value : <i>{t("stats.brak_informacji")}</i>}
                    </span>)
                } else if (onlyView === 1 && match_info[key].value) {
                    rows.push(<span style={{ marginRight: 5 }}>
                        <Icon color='grey' name={match_info[key].icon} />{t("stats." + match_info[key].name)}: {match_info[key].value ? match_info[key].value : <i>{t("stats.brak_informacji")}</i>}
                    </span>)
                }
    
            }
            return rows;
        } catch (e) {
            console.log(e)
        }
    }


    function getJsonTMP(dataRow,homeScore,visitorScore){
       
        return {match_member1_id:dataRow.sides.home.team.id,
            member1_id:dataRow.sides.home.team.member_id,
            member1_img_file:dataRow.sides.home.team.url_img,
            member1_name:dataRow.sides.home.team.name,
            member1_score:homeScore,
            match_member2_id:dataRow.sides.visitor.team.id,
            member2_id:dataRow.sides.visitor.team.member_id,
            member2_img_file:dataRow.sides.visitor.team.url_img,
            member2_name:dataRow.sides.visitor.team.name,
            member2_score:visitorScore,
            match_details_ex:dataRow.match_details_ex,
            match_info:dataRow.match_info,
            subtournament_league_match_id:dataRow.id}
    }

    return (
        < >

            {propsRoot.subT_type && propsRoot.onlyView === 0 && jsonBreacket !== null && typeof jsonBreacket != "undefined" && jsonBreacket.length > 0 ?
                <Message className='hiddenPrint' color='brown' size='mini'
                    icon='winner'
                    header={propsRoot.subT_type}
                    content='Rodzaj rozgrywanego turnieju'
                />
                : ""}
           
            {jsonBreacket !== null && typeof jsonBreacket != "undefined" && jsonBreacket.length > 0 && propsRoot.createTournament !== true ?
            
                <BracketGenCurrentMatch tournamentJsonRoot={propsRoot.tournamentJsonRoot} hide_bracket_names={propsRoot && propsRoot.remoteJsonFull &&  propsRoot.remoteJsonFull.hide_bracket_names ? propsRoot.remoteJsonFull.hide_bracket_names : false } isDarkTheme={propsRoot.isDarkTheme} viewTV={propsRoot.viewTV} systemId={propsRoot.systemId} onlyView={propsRoot.onlyView} subtournament_id={tournamentId} jsonBreacket={jsonBreacket} eventShow={eventShow} /> : ""}
     
            {propsRoot.onlyView === 0 && propsRoot.remoteJsonFull && propsRoot.remoteJsonFull.struct_confirmed=== 1 ? 
            <Checkbox className='hiddenPrint' checked={propsRoot && propsRoot.remoteJsonFull && propsRoot.remoteJsonFull.hide_bracket_names ? propsRoot.remoteJsonFull.hide_bracket_names ? true : false : false } onChange={(e,d)=>hide_bracket_names(d.checked,tournamentId)} toggle label="Ukryj nazwy miejsc na drabince"/>    
            :""}
            <div className='BracketTGStickyZI' style={{ position: "relative" }} ref={contextRefZoom}>

                {jsonBreacket !== null && typeof jsonBreacket != "undefined" && jsonBreacket.length > 0 ? propsRoot.offZoom ? "" :
                    stopStickyActive() ?
                        <Sticky
                            bottomOffset={50}
                            context={contextRefZoom}
                            offset={50}
                        >
                            <div className='hiddenPrint' style={{ width: 150, position: "absolute", right: 10, zIndex: 10000 }}>
                                <div style={{ textAlign: "center", color: "#4b5155", fontSize: 12 }}>ZOOM</div>
                                <Slider
                                    color="brown"
                                    inverted={false}

                                    value={zoomVal}
                                    settings={{
                                        start: 100,
                                        min: 50,
                                        max: 100,
                                        step: 10,
                                        onChange: value => {
                                            changeZoom(value)
                                        },

                                    }}

                                />
                            </div>

                        </Sticky>

                        :
                        <div className='hiddenPrint' style={{ width: 150, position: "absolute", right: 10, zIndex: 10000 }}>
                            <div style={{ textAlign: "center", color: "#4b5155", fontSize: 12 }}>ZOOM</div>
                            <Slider
                                color="brown"
                                inverted={false}

                                value={zoomVal}
                                settings={{
                                    start: 100,
                                    min: 50,
                                    max: 100,
                                    step: 10,
                                    onChange: value => {
                                        changeZoom(value)
                                    },

                                }}

                            />
                        </div>
                    : ""}

                <div className='BracketGenerator' ref={wrapperRef} style={{ zoom: zoomVal + "%" }}>

                    {propsRoot.ttname != null && propsRoot.third_place_encounter !== null && typeof propsRoot.third_place_encounter !== "undefined" && propsRoot.struct_confirmed === 0 && propsRoot.ttype === "CUP_SIMPLE" && propsRoot.onlyView === 0 ?
                        propsRoot.third_place_encounter === 0 ? <Button size='mini' icon labelPosition='left' color='orange' onClick={() => setthird_place_encounter(true)}><Icon name='add' />DODAJ POJEDYNEK O 3 MIEJSCE</Button> : <Button icon labelPosition='left' color='orange' onClick={() => setthird_place_encounter(false)} size='mini' ><Icon name='minus' />USUŃ POJEDYNEK O 3 MIEJSCE</Button>
                        : ""}



                    {jsonBreacket !== null && typeof jsonBreacket != "undefined" && jsonBreacket.length > 0 ? jsonBreacket.map((line, k) => (
                        <div key={k}>

                            {k > 0 && k < (cutBracket === true ? 3 : 2) && totalMembers > 16 && BlockCut === false ?
                                <>
                                    <Container textAlign='center'><Checkbox style={{ marginBottom: 10, marginTop: 10 }} toggle label={t("podziel_drabinke")} checked={cutBracket} onChange={(e, d) => setcutBracket(d.checked)} /></Container>
                                </> : ""
                            }

                            <BracketGenerator key={k} games={[line]} GameComponent={GameComponent} homeOnTop={true} />
                            {(k + 1) !== jsonBreacket.length ? <div className='page-break'></div> : ""}

                        </div>
                    )) : ''}


                </div>
            </div>


            <Modal
                // onClose={() => exitModal()}
                onOpen={() => setOpen(true)}
                open={open}
                centered={width < 900 ? false : true}
            //closeIcon


            >
                <Modal.Header>
                    <Grid stackable>
                        <Grid.Row>
                            <Grid.Column width={12} verticalAlign="middle">
                                <Header as='h2' textAlign='center'>
                                    <Icon name='info circle' color="red" />
                                    <Header.Content>{propsRoot.onlyView === 1 ? "WYNIKI" : EMPTY_BRACKET ? "Przypisz uczestnika" : "Wprowadź wynik"}</Header.Content>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign="right" only='large screen'>
                                <Image alt="logo" floated='right' size='big' centered src={LogoTeamLigaGenerator1} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Modal.Header>
                <Modal.Content style={{ backgroundColor: "#f5f5f5" }}  >
                    <Modal.Description >
                     
                        {propsRoot.onlyView === 1 && openData ?
                            <Container textAlign='center'>
                           
                              
                                <Grid verticalAlign='middle' fluid stackable columns={3}>
                                    <Grid.Row>
                                        <Grid.Column width={7} textAlign='center'>
                                            <Segment textAlign='center'>
                                                <b><label style={{ fontSize: 22 }}>
                                                    <div className='imgPlayer2'><ShowImgAvatar urlpic={openData && openData.sides.home.team.url_img ? openData.sides.home.team.url_img : null} mtype={propsRoot && propsRoot.remoteJsonFull ? propsRoot.remoteJsonFull.member_type : "0"} msize={'large'} /></div>
                                                    {openData ? openData.sides.home.team.name : '?'}</label></b><br />
                                                <span style={{ fontSize: 13 }}>UCZESTNIK</span>
                                            </Segment>
                                        </Grid.Column>
                                        <Grid.Column textAlign='center' width={2}>
                                            
                                            <label style={{ fontSize: 25, fontWeight: "bold", color: "#737373" }} className={propsRoot && propsRoot.remoteJsonFull && propsRoot.remoteJsonFull.member_type === "TEAM" && openData.match_details_ex ? "tllink cursorPointer" : ""} onClick={() => propsRoot && propsRoot.remoteJsonFull &&  propsRoot.remoteJsonFull.member_type === "TEAM" && openData.match_details_ex ?  setopenModalmatch_details_ex({ open: true, row: getJsonTMP(openData,form.homeScore,form.visitorScore), onlyView: propsRoot.onlyView, st: propsRoot.remoteJsonFull,subtournament_id:propsRoot.tournamentId }) :""}>
                                                {form.homeScore !== null ? form.homeScore : "-"}:{form.visitorScore !== null ? form.visitorScore : "-"}</label>
                                            <br />
                                            <span style={{ fontSize: 13 }}>WYNIK</span>
                                        </Grid.Column>
                                        <Grid.Column width={7} textAlign='center'>
                                            <Segment>
                                                <b><label style={{ fontSize: 22 }} >
                                                    <div className='imgPlayer2'><ShowImgAvatar urlpic={openData && openData.sides.visitor.team.url_img ? openData.sides.visitor.team.url_img : null} mtype={propsRoot && propsRoot.remoteJsonFull ? propsRoot.remoteJsonFull.member_type : "0"} msize={'large'} /></div>
                                                    {openData ? openData.sides.visitor.team.name : '?'}</label></b><br />
                                                <span style={{ fontSize: 13 }}>UCZESTNIK</span>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                                <ShowScoreDetails refreshView={authContext.refreshView} systemId={propsRoot.systemId} match_detailstmp={{ subtournament_match_id: openData.id, bracket: true, match_details: form.match_details, match_member1_id: openData.sides.home.team.id, match_member2_id: openData.sides.visitor.team.id, reverse: false, checkboxMD: true }} />
                                {openData && openData.match_info ? getmatch_info(openData.match_info, openData,propsRoot.onlyView,propsRoot.tournamentId) : ""}
                            </Container> :
                            <Container textAlign='center'>
                                  
                                {EMPTY_BRACKET ? <Container textAlign='left'>
                                    <Message warning>
                                    Po przypisaniu <b>obojga uczestników</b> nie będzie już możliwości ich edytowania!
                                    </Message>
                                    <Form size='small'>
                                       
                                            <Form.Field
                                                control={Select} selectOnBlur={false}
                                                options={userList}
                                                onChange={(event, data) => {
                                                    replaceTmpMemberInBracket(openData ? openData.sides.home.team.id : 0, data.value)
                                                }}
                                                search
                                                label={openData ? openData.sides.home.team.name : '?'}
                                                placeholder={"Wybierz uczestnika"}

                                            /> 
                                      
                                            <Form.Field
                                                control={Select} selectOnBlur={false}
                                                options={userList}
                                                onChange={(event, data) => {
                                                    replaceTmpMemberInBracket(openData ? openData.sides.visitor.team.id : 0, data.value)
                                                }}
                                                search
                                                label={openData ? openData.sides.visitor.team.name : '?'}
                                                placeholder={"Wybierz uczestnika"}

                                            /> 


                                    </Form>
                                    {openData ?
                                        <GetScoreDetails subtournamentJsonRoot={propsRoot.remoteJsonFull} tournamentJsonRoot={propsRoot.tournamentJsonRoot} hiddenScoreDetails={true} tournament_free={propsRoot.tournament_free} systemId={propsRoot.systemId} openModalScoreTMP={{ subtournament_match_id: openData.id, member1_name: openData.sides.home.team.name, member2_name: openData.sides.visitor.team.name, member1_score: form.homeScore, member2_score: form.visitorScore, match_details: form.match_details, match_place: form.match_place, match_member1_id: openData.sides.home.team.id, match_member2_id: openData.sides.visitor.team.id }} openModalScore={form} setopenModalScore={setfrom} />
                                        : ""}
                                </Container> : <>
                                {(openData && openData.sides.visitor.team.id<1 && openData.sides.home.team.id<1) ? "" :
                                <Segment>
                                    <Header>Runda: {openData ? openData.name : '?'}</Header>
                                   
                                    <Form size='small' onSubmit={modifiDataJsonPre}>
                               
                                        <Form.Field inline>
                                            <Grid verticalAlign='middle' fluid stackable columns={3}>
                                                <Grid.Row>
                                                    <Grid.Column width={7} textAlign='center'><b><label style={{ fontSize: 16, color: "#646bfa" }}>
                                                        <div className='imgPlayer2'><ShowImgAvatar urlpic={openData && openData.sides.home.team.url_img ? openData.sides.home.team.url_img : null} mtype={propsRoot && propsRoot.remoteJsonFull ? propsRoot.remoteJsonFull.member_type : "0"} msize={"massive"} /></div>
                                                        {openData ? openData.sides.home.team.name : '?'}
                                                    </label></b>
                                                        <Input disabled={blockEditScore(form.visitorScore, form.homeScore, propsRoot.systemId, form ? form.match_details : null) || isloading} autoFocus fluid type="number" value={form.homeScore} onChange={(e, d) => setfrom({ ...form, homeScore: (d.value), match_details: propsRoot.systemId === 5 ? null : form.match_details })} style={{ padding: 5, margin: 5, border: "2px solid #c9ccff", borderRadius: 5 }} />
                                                    </Grid.Column>
                                                    <Grid.Column textAlign='center' width={2}>
                                                        <label style={{ fontSize: 25, fontWeight: "bold", color: "#737373" }}>VS {propsRoot && propsRoot.remoteJsonFull && propsRoot.remoteJsonFull.member_type === "TEAM" && openData && openData.match_details_ex ? <Icon name="balance scale" className='cursorPointer' color='blue' onClick={() => propsRoot.remoteJsonFull.member_type === "TEAM" && openData.match_details_ex ?  setopenModalmatch_details_ex({ open: true, row: getJsonTMP(openData,form.homeScore,form.visitorScore), onlyView: propsRoot.onlyView, st: propsRoot.remoteJsonFull,subtournament_id:propsRoot.tournamentId }) :""}/>: ""}</label>
                                                    </Grid.Column>
                                                    <Grid.Column width={7} textAlign='center'>
                                                        <div className='imgPlayer2'><ShowImgAvatar urlpic={openData && openData.sides.visitor.team.url_img ? openData.sides.visitor.team.url_img : null} mtype={propsRoot && propsRoot.remoteJsonFull ? propsRoot.remoteJsonFull.member_type : "0"} msize={"massive"} /></div>

                                                        <b><label style={{ fontSize: 16, color: "#fa6464" }} >{openData ? openData.sides.visitor.team.name : '?'}</label></b><br />
                                                        <Input disabled={blockEditScore(form.visitorScore, form.homeScore, propsRoot.systemId, form ? form.match_details : null) || isloading} fluid type="number" value={form.visitorScore} onChange={(e, d) => setfrom({ ...form, visitorScore: (d.value), match_details: propsRoot.systemId === 5 ? null : form.match_details })} style={{ padding: 5, margin: 5, border: "2px solid #ffc9c9", borderRadius: 5 }} />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            
                                        </Form.Field>

                                        <Button size="tiny"
                                            content="ZAPISZ WYNIK"
                                            labelPosition='right'
                                            icon='checkmark'
                                            fluid
                                            type="submit"
                                            color='green'
                                            loading={isloading}
                                            disabled={isloading}
                                        />
                                    </Form>
                                    </Segment>
                                    }
                                    {openData && openData.match_info ? getmatch_info(openData.match_info, openData,propsRoot.onlyView,propsRoot.tournamentId) : ""}
                                            {openData  ?
                                                <GetScoreDetails subtournamentJsonRoot={propsRoot.remoteJsonFull} tournamentJsonRoot={propsRoot.tournamentJsonRoot} tournament_free={propsRoot.tournament_free} systemId={propsRoot.systemId} openModalScoreTMP={{ subtournament_match_id: openData.id, member1_name: openData.sides.home.team.name, member2_name: openData.sides.visitor.team.name, member1_score: form.homeScore, member2_score: form.visitorScore, match_details: form.match_details, match_place: form.match_place, match_member1_id: openData.sides.home.team.id, match_member2_id: openData.sides.visitor.team.id }} openModalScore={form} setopenModalScore={setfrom} />
                                                : ""}
                                </>}
                            </Container>
                        }
                    </Modal.Description>
                    {messageStatus.state === 1 ?
                        <Message negative>
                            <Message.Header>Bład:</Message.Header>
                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                    {messageStatus.state === 2 ?
                        <Message positive>

                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                </Modal.Content>
                <Modal.Actions>
                    {propsRoot.onlyView === 1 || EMPTY_BRACKET || (openData && openData.sides.visitor.team.id<1 && openData.sides.home.team.id<1) ? "" : <Button floated='left' size="tiny" color='grey' disabled={isloading} labelPosition='right' icon='trash' onClick={() => delMatch()} content="KASUJ WYNIK" />}
                    {propsRoot.onlyView === 0 && EMPTY_BRACKET ? <Message warning>Zanim wyjdziesz z tego okna upewnij się, że dobrze przypisałeś uczestników! <Button size="tiny" labelPosition='right' icon='close' color='grey' onClick={() => exitModal()} content="WYJDŹ" /></Message> : <Button size="tiny" labelPosition='right' icon='close' color='grey' onClick={() => exitModal()} content="WYJDŹ" />}
                    

                </Modal.Actions>
            </Modal>



            <Modal
                onClose={() => exitModal()}
                onOpen={() => setOpenUsersAdd(true)}
                open={openUsersAdd}
                centered={width < 900 ? false : true}
                closeIcon
                dimmer='blurring'

            >
                <Modal.Header>Przypisz uczestnika</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Container textAlign='left'>

                            <Form size='small' >
                                <Form.Field
                                    control={Select} selectOnBlur={false}
                                    options={userList}
                                    value={formUsersAdd.homeUser}
                                    onChange={(event, data) => {
                                        setfromUsersAdd({ ...formUsersAdd, homeUser: data.value })
                                    }}
                                    search
                                    label={openDataUsersAdd ? openDataUsersAdd.sides.home.team.name : '?'}
                                    placeholder={openDataUsersAdd ? openDataUsersAdd.sides.home.team.name : '?'}

                                />

                                <Form.Field
                                    control={Select} selectOnBlur={false}
                                    options={userList}
                                    value={formUsersAdd.visitorUser}
                                    onChange={(event, data) => {
                                        setfromUsersAdd({ ...formUsersAdd, visitorUser: data.value })
                                    }}
                                    search
                                    label={openDataUsersAdd ? openDataUsersAdd.sides.visitor.team.name : '?'}
                                    placeholder={openDataUsersAdd ? openDataUsersAdd.sides.visitor.team.name : '?'}

                                />


                            </Form>

                        </Container>
                    </Modal.Description>
                    {messageStatus.state === 1 ?
                        <Message negative>
                            <Message.Header>Bład:</Message.Header>
                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                    {messageStatus.state === 2 ?
                        <Message positive>

                            <p>{messageStatus.text}</p>
                        </Message> : ''}
                </Modal.Content>
                <Modal.Actions>


                    <Button size="tiny"
                        content="ZATWIERDŹ"
                        labelPosition='right'
                        icon='checkmark'
                        loading={isloading}
                        disabled={isloading}
                        onClick={() => modifiDataJsonUserACC()}
                        color='green'
                    />
                    <Button size="tiny" labelPosition='right' icon='close' color='grey' onClick={() => exitModal()} content="WYJDŹ" />
                </Modal.Actions>
            </Modal>
            <EditLeagueMatchInfo openModalmatch_info={openModalmatch_info} setopenModalmatch_info={setopenModalmatch_info} />
            <EditLeagueMatchDetailsEx tournament_free={propsRoot.tournamentJsonRoot ? propsRoot.tournamentJsonRoot.tournament_free : 0 } ttype={propsRoot.remoteJsonFull ? propsRoot.remoteJsonFull.type : null} systemId={propsRoot.systemId} openModalmatch_details_ex={openModalmatch_details_ex} setopenModalmatch_details_ex={setopenModalmatch_details_ex} />
            <GooglePhotosViewer url={GooglePhotosViewerStatus.url} openView={GooglePhotosViewerStatus.openView} setGooglePhotosViewerStatus={setGooglePhotosViewerStatus} />
        </>

    )
}

export default BracketGen;