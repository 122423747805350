import React, { useState, useEffect, useContext } from 'react';
import { Icon, Header, Divider, Form, Input, Button, Container, Table } from 'semantic-ui-react'
import AuthContext from '../../../../context/authContext';
import { useToasts } from 'react-toast-notifications';
import useAuth from '../../../../hooks/useAuth';
import { ConnectWS } from '../../../../ConnectWS'
import { addDateToDate, changeFormatDate, checkDateStartBeforeNow, getTabByFiltr } from '../../../../myfunctions';
import validator from 'validator';
import ShowPagination from '../../../../tools/ShowPagination';

function ShareTournamentToUsers(props) {
  const { addToast } = useToasts();
  const authContext = useContext(AuthContext);
  const [loading, setloading] = useState(false);
  const [auth, setAuth] = useAuth();// eslint-disable-line no-unused-vars
  const [Tournament, setTournament] = useState([]);// eslint-disable-line no-unused-vars
  const [SubTournament, setSubTournament] = useState([]);// eslint-disable-line no-unused-vars
  const [SharedTournamentsList, setSharedTournamentsList] = useState({ current: [], filter: null, data: [], activePage: 1 });



  const [form, setForm] = useState({
    tournament_id: 0,
    subtournament_id: 0,
    access_user_email: "",
    description: null,
    validEmail: true,
  });

  function clearFormData() {
    setForm({
      tournament_id: 0,
      subtournament_id: 0,
      access_user_email: "",
      description: null,
      validEmail: true,
    })
  }

  useEffect(() => {
    clearFormData()
    getTournaments()
    getSharedTournaments()
    return () => { }; // use effect cleanup to set flag false, if unmounted
  }, [])// eslint-disable-line react-hooks/exhaustive-deps


  function getSharedTournaments() {
    ConnectWS("/generator/getSharedTournaments", authContext.token, { is_create_user: true }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {

        setSharedTournamentsList({ current: response.data, filter: null, data: response.data, activePage: 1 })
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

        //console.log(response);
      }



    });
  }

  function getTournaments() {
    ConnectWS("/generator/getTournaments", authContext.token, { skip_archived: false }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0
        response.data.forEach((data, i) => {
          if (data.tournament_archived === 0) {
            if (!checkDateStartBeforeNow(addDateToDate(data.start_date, 365))) {
            } else {
              dictemat.push({ key: i, text: changeFormatDate(data.start_date) + " - " + data.name.toUpperCase(), value: data.tournament_id })
            }
          }
        })
        setTournament(dictemat);
      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

        //console.log(response);
      }



    });
  }


  function ChangeTournamentBox(data) {
    setForm({ ...form, tournament_id: data.value })
    ConnectWS("/generator/getSubtournaments", authContext.token, { tournament_id: data.value }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);
      } else if (response && response.status && Number(response.status) === 200) {

        let dictemat = []
        dictemat.length = 0
        dictemat.push({ key: -1, text: "WSZYSTKIE KATEGORIE", value: 0 })
        response.data.forEach((data, i) => {
          dictemat.push({ key: i, text: data.name.toUpperCase(), value: data.subtournament_id })
          //dictemat.push({ key: i, text: data.Login, value: data.Login })
        })
        setSubTournament(dictemat);


      } else {

        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }
        //console.log(response);
      }



    });
  }


  const submitModal = e => {
    e.preventDefault();
    if (form.access_user_email !== null && form.access_user_email !== "") {
      if (validator.isEmail(form.access_user_email.trim())) {
      } else {
        setForm({ ...form, validEmail: false })
        return
      }
    }

    if (form.tournament_id === 0) {
      addToast('Proszę wybrać rozgrywki', { appearance: 'warning' });
      return
    }
    //generator/addUserTournamentAccess -> tournament_id, subtournament_id, access_user_email, description (opcjonalnie)
    setloading(true)
    ConnectWS("generator/addUserTournamentAccess", authContext.token, { ...form }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });
        setTimeout(() => {
          setAuth(false);
        }, 4000);

      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
        getSharedTournaments()
        clearFormData()
      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }
      setloading(false)


    });


  }

  function removeUserFromTournamentAccess(tournament_id, subtournament_id, access_user_id) {
    ConnectWS("/generator/removeUserFromTournamentAccess", authContext.token, { tournament_id: tournament_id, subtournament_id: subtournament_id, access_user_id: access_user_id }, function (response, logout) {

      if (logout === 1) {
        addToast('Zaraz nastapi wylogowanie: ' + response.error, { appearance: 'error' });

      } else if (response && response.status && Number(response.status) === 200) {
        addToast(response.data.message, { appearance: 'success' });
        getSharedTournaments()


      } else {
        if (logout === 101) {
          addToast(response, { appearance: 'info' });
        } else if (logout === 102) {
          addToast(response, { appearance: 'warning' });
        } else {
          addToast(response, { appearance: 'error' });
        }

      }




    });
  }

  return (
    <> <Header as='h5' textAlign='center' block>
      <Icon name='share' />
      <Header.Content>Udostępnij rozgrywki</Header.Content>
    </Header>
      <Divider />
      <Container textAlign='center'>
        <Header as='h3' textAlign='center'>
          <Header.Content>Udostępnij rozgrywki do zarządzania innej osobie</Header.Content>
        </Header>

        Osoba musi posiadać konto w aplikacji Turniej-Generator


      </Container>
      <Form onSubmit={submitModal} size='tiny'>
        <Form.Select
          fluid
          search
          required={true}
          label="Rozgrywki"
          options={Tournament}
          value={form.tournament_id}
          onChange={(e, data) => ChangeTournamentBox(data)}
          placeholder='rozgrywki'
        />

        <Form.Select
          fluid
          search
          required={true}
          label="Kategoria"
          placeholder='kategoria'
          options={SubTournament}
          value={form.subtournament_id}
          onChange={(e, data) => setForm({ ...form, subtournament_id: data.value })}

        />
        <Form.Field
          control={Input}
          value={form.access_user_email}
          onChange={(event, data) => setForm({ ...form, access_user_email: data.value })}
          label="Email osoby, której chcesz udostępnić rozgrywki"
          required={true}
          placeholder="Email"
          error={form.validEmail ? null : "Niepoprawny formatu adresu email"}
          type='email'
        />

        <Form.Field
          control={Input}
          value={form.description}
          onChange={(event, data) => setForm({ ...form, description: data.value })}
          label="Opis"
          placeholder="Opis"
        />


        <Button size='tiny' color='blue' fluid disabled={loading ? true : false} loading={loading ? true : false} >UDOSTĘPNIJ ROZGRYWKI</Button>
      </Form>

      <Divider />
      <Input size='mini' style={{ marginBottom: 10 }} fluid icon='search' onChange={(e, d) => getTabByFiltr(d.value, SharedTournamentsList, setSharedTournamentsList, 10)} placeholder={"Szukaj..."} />

      {SharedTournamentsList.current ?
        <div style={{ overflowX: "auto", width: "100%" }} >
          <Table singleLine size='small' celled striped compact unstackable >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1} textAlign='center'>NAZWA TURNIEJU</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='center'>NAZWA KATEGORII</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='center'>ROZPOCZĘCIE ROZGRYWEK</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='center'>ZAKOŃCZENIE ROZGRYWEK</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='center'>UDOSTĘPNIONY DLA</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='center'>UDOSTĘPNIONY OPIS</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign='center'></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {SharedTournamentsList.current && SharedTournamentsList.current.length > 0 ? SharedTournamentsList.current.map((row, i) =>

                <Table.Row>

                  <Table.Cell width={1} textAlign='center'>{row.tournament_name}</Table.Cell>
                  <Table.Cell width={1} textAlign='center'>{row.subtournament_name}</Table.Cell>
                  <Table.Cell width={1} textAlign='center'>{row.start_date}</Table.Cell>
                  <Table.Cell width={1} textAlign='center'>{row.stop_date}</Table.Cell>
                  <Table.Cell width={1} textAlign='center'>{row.access_user}</Table.Cell>
                  <Table.Cell width={1} textAlign='center'>{row.access_user_description}</Table.Cell>
                  <Table.Cell width={1} textAlign='center'>
                    <Icon className='cursorPointer' size='large' onClick={() => removeUserFromTournamentAccess(row.tournament_id, row.subtournament_id, row.access_user_id)}
                      name="trash" title="Skasuj plik" />
                  </Table.Cell>
                </Table.Row>
              ) : <Table.Row>
                <Table.Cell colSpan='5' textAlign='center'>
                  BRAK DANYCH
                </Table.Cell>
              </Table.Row>}
            </Table.Body>
          </Table>
        </div>
        : ""}
      <ShowPagination darkOn={false} darkForce={false} myTab={SharedTournamentsList} setMyTab={setSharedTournamentsList} rowCurrent={10} />


    </>
  )
}

export default ShareTournamentToUsers;