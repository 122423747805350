import React, { useEffect, useState, } from 'react';
import { Icon, Table, } from 'semantic-ui-react'
import { useToasts } from 'react-toast-notifications';

import { checkShowDraw, getFormIcon, getStosunekPTK, getlinkplayerprofile, isEmpty, mobileWSmall } from '../../../myfunctions';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

import ShowImgAvatar from '../../../tools/ShowImgAvatar';
import { useTranslation } from 'react-i18next';


function GetTableScore({subtournamentRoot, member_type,systemId, onlyView, TableData, league_info, typeT, subtournament_id, tournament_free, tmpTotalPointInfoArr, settmpTotalPointInfoArr, isDarkTheme }) {
  const { addToast } = useToasts();

  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars
  const { t } = useTranslation();
  const [dataRow, setdataRow] = useState([])

  const [showDraw, setshowDraw] = useState(false)
  const [showDetails, setshowDetails] = useState(false)



  useEffect(() => {

 
    setshowDraw(checkShowDraw(systemId,member_type))
    if (TableData && TableData.length > 0) {

      let cw = 0;
      let cl = 0;
      TableData.forEach(element => {
        if (!isEmpty(element.total_details_points_winners)) {
          cw++
        }
        if (!isEmpty(element.total_details_points_losers)) {
          cl++
        }
      });
      if (cw > 0 || cl > 0) {
        setshowDetails(true)
      }
      setdataRow(TableData)
    }

    return () => {
    }; // use effect cleanup to set flag false, if unmounted
  }, [TableData])// eslint-disable-line react-hooks/exhaustive-deps


  function updateRowFinalTable(row, subtournament_id, tournament_free) {
    if (tournament_free === 0) {
      addToast("ZABLOKOWANE - Ta opcja jest dostępna tylko w pakiecie srebrnym lub złotym", { appearance: 'warning' })
    } else {
      settmpTotalPointInfoArr({
        id: row.id,
        subtournament_id: subtournament_id,
        order_nr_range: row.order_nr_range,
        matches_played: row.matches_played,
        wins: row.wins,
        draws: row.draws,
        loses: row.loses,
        total_points_winners: row.total_points_winners,
        total_points_losers: row.total_points_losers,
        subtournament_point: row.subtournament_point,
      })
    }
  }


  return (
    <div style={{ overflowX: "auto", marginTop: 5, width: width < 700 ? "100%" : "100%" }} >
      <Table className='stickyf2c' size='small' inverted={isDarkTheme} celled striped compact unstackable >
        <Table.Header>
          <Table.Row>
         
            {onlyView === 0 ?
              <Table.HeaderCell className='cursorPointer ' singleLine style={{ width: 50 }} textAlign='center'></Table.HeaderCell>
              : ""}
            <Table.HeaderCell singleLine width={1} textAlign='center'>{width > 1000 ? t("WidokTurnieju.tabela_miejsce") : t("WidokTurnieju.tabela_miejsce_mala")}</Table.HeaderCell>
            <Table.HeaderCell textAlign='center' >{t("WidokTurnieju.tabela_uczestnik")}</Table.HeaderCell>
            {(subtournamentRoot && subtournamentRoot.cycle_id) || onlyView===0 ? <Table.HeaderCell width={1} textAlign='center' singleLine style={{ lineHeight: "12px" }}>{width > 1000 ? t("WidokTurnieju.tabela_ptk"): "PKT"} 
              {typeT === "LEAGUE" ? "" : <> <br /><span style={{ fontSize: 9 }}>{width > 1000 ? t("WidokTurnieju.tabela_ptk_t") :""}</span></>}
            </Table.HeaderCell>: ""}
            <Table.HeaderCell width={1} textAlign='center' singleLine>{width > 1000 ? t("WidokTurnieju.tabela_pojedynki") : "LP"}</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign='center' singleLine>{width > 1000 ? t("WidokTurnieju.tabela_wygrane") : "W"}</Table.HeaderCell>
            {showDraw ? <Table.HeaderCell width={1} textAlign='center' singleLine>{width > 1000 ? t("WidokTurnieju.tabela_remis") : "R"}</Table.HeaderCell> : ""}
            <Table.HeaderCell width={1} textAlign='center' singleLine>{width > 1000 ? t("WidokTurnieju.tabela_przegrane") : "P"}</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign='center' singleLine>{width > 1000 ? t("WidokTurnieju.tabela_bilans"): "B"}</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign='center' singleLine>{t("WidokTurnieju.tabela_stosunek")}</Table.HeaderCell>
            {showDetails ? <>
              <Table.HeaderCell width={1} textAlign='center' singleLine style={{ lineHeight: "12px" }}>{width > 1000 ? t("WidokTurnieju.tabela_bilans"): "B"}
                <br /><span style={{ fontSize: 9 }}>{width > 1000 ? t("WidokTurnieju.tabela_bilans_szczegoly") : "SZCZEG. PKT"}</span></Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign='center' singleLine style={{ lineHeight: "12px" }}>{t("WidokTurnieju.tabela_stosunek")}
                <br /><span style={{ fontSize: 9 }}>{width > 1000 ? t("WidokTurnieju.tabela_bilans_szczegoly") : "SZCZEG. PKT"}</span></Table.HeaderCell>
            </> : ""}
            {typeT === "LEAGUE" ? <Table.HeaderCell width={1} textAlign='center' >{t("WidokTurnieju.tabela_forma")}</Table.HeaderCell> : ""}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dataRow.length > 0 ?
            dataRow.map((line3, i) => <Table.Row key={i} className={league_info && i < league_info.winners_count ? 'winnerScoreP' : league_info && i >= (dataRow.length - league_info.losers_count) ? 'loserScoreP' : ''} warning={tmpTotalPointInfoArr && tmpTotalPointInfoArr.id === line3.id ? true : false}>
              {onlyView === 0 ? <Table.Cell className='cursorPointer ' textAlign='center' style={{ width: 50 }}><Icon name="edit" size='large' color='orange' className='cursorPointer hiddenPrint' onClick={() => updateRowFinalTable(line3, subtournament_id, tournament_free)} /></Table.Cell> : ""}
              <Table.Cell textAlign='center' singleLine>{line3.order_nr_range}</Table.Cell>
              <Table.Cell textAlign='left' ><figure className='imgPlayer'><ShowImgAvatar urlpic={line3.img_file} mtype={member_type} /><figcaption>{getlinkplayerprofile(line3.member_id, line3.member_name, 1, width < mobileWSmall() ? 13 : 16, "_blank")}</figcaption></figure></Table.Cell>
              {(subtournamentRoot && subtournamentRoot.cycle_id) || onlyView===0 ? <Table.Cell textAlign='center'>{line3.subtournament_point} </Table.Cell>: ""}
              <Table.Cell textAlign='center'>{line3.matches_played}</Table.Cell>
              <Table.Cell textAlign='center'>{line3.wins}</Table.Cell>
              {showDraw ? <Table.Cell textAlign='center'>{line3.draws}</Table.Cell> : ""}
              <Table.Cell textAlign='center'>{line3.loses}</Table.Cell>
              <Table.Cell textAlign='center' singleLine>{line3.total_points_winners}:{line3.total_points_losers}</Table.Cell>
              <Table.Cell textAlign='center' singleLine>{getStosunekPTK(line3.total_points_winners, line3.total_points_losers)}</Table.Cell>
              {showDetails ? <>
                <Table.Cell textAlign='center' singleLine>{line3.total_details_points_winners ? line3.total_details_points_winners : 0}:{line3.total_details_points_losers ? line3.total_details_points_losers : 0}</Table.Cell>
                <Table.Cell textAlign='center' singleLine>{getStosunekPTK(line3.total_details_points_winners, line3.total_details_points_losers)}</Table.Cell>
              </> : ""}
              {typeT === "LEAGUE" && line3.last_5_matches_stats && line3.last_5_matches_stats.length === 5 ?
                <Table.Cell textAlign='center'>
                  <div className='formawrap'>
                    {getFormIcon(line3.last_5_matches_stats[0])}
                    {getFormIcon(line3.last_5_matches_stats[1])}
                    {getFormIcon(line3.last_5_matches_stats[2])}
                    {getFormIcon(line3.last_5_matches_stats[3])}
                    {getFormIcon(line3.last_5_matches_stats[4])}
                  </div>
                </Table.Cell>
                : ""}

            </Table.Row>)
            : ""}

        </Table.Body>


      </Table>
    </div>
  )
}

export default GetTableScore;



