import React, { useContext, useEffect, useState } from 'react';
import { Button, Container, Form, Grid, Header, Icon, Image, Input, Message, Modal, Table } from 'semantic-ui-react';
import LogoTeamLigaGenerator from '../assets/logotgver1.svg';
import { ConnectWS } from '../ConnectWS';
import AuthContext from '../context/authContext';
import { useToasts } from 'react-toast-notifications';
import validator from 'validator';

function AddEmailForMember(props) {
    const { addToast } = useToasts();
    const [openAddEmail, setopenAddEmail] = useState({ open: false, data: null })
    const [loading, setloading] = useState(false);
    const [emailData, setemailData] = useState(null);
    const authContext = useContext(AuthContext);

    useEffect(() => {
        if (props.OpenModalAEFM && props.OpenModalAEFMDATA) {
            setopenAddEmail({ open: true, data: props.OpenModalAEFMDATA, force: props.dateForce })
        }
        return () => { }; // use effect cleanup to set flag false, if unmounted
    }, [props.OpenModalAEFM, props.OpenModalAEFMDATA])// eslint-disable-line react-hooks/exhaustive-deps


    function closeModal() {
        setopenAddEmail({ open: false, data: null })
        if (props.setdataMailMembers) {
            props.setdataMailMembers({ open: false, data: null })
        }

    }

    const addUserEmail = e => {
        e.preventDefault();

        /*
email
force
userData.tournament_member_ids = []
        */
        if (validator.isEmail(emailData)) {
        } else {
            addToast("Popraw email", { appearance: 'warning' });
            return
        }
        setloading(true)
        //ConnectWS("/generator/addEmailToMember", authContext.token, { userData: openAddEmail.data, email: emailData, force: openAddEmail.force }, function (response, logout) {


        ConnectWS("/generator/addEmailToNonEmailMember", authContext.token, { member_id: openAddEmail.data.member_id, email: emailData }, function (response, logout) {

            if (logout === 1) {


            } else if (response && response.status && Number(response.status) === 200) {
                setemailData(null)
                closeModal()
                authContext.changeRefreshView(authContext.refreshView + 1)
                addToast(response.data.message, { appearance: 'success' });


            } else {
                closeModal()
                if (logout === 101) {
                    addToast(response, { appearance: 'info' });
                } else if (logout === 102) {
                    addToast(response, { appearance: 'warning' });
                } else {
                    addToast(response, { appearance: 'error' });
                }
                //console.log(response);
            }
            setloading(false)


        });

    }

    return (
        <Modal
            onClose={() => closeModal()}
            open={openAddEmail.open}
        >
            <Modal.Header>
                <Grid stackable>
                    <Grid.Row>
                        <Grid.Column width={12} verticalAlign="middle">
                            <Header as='h2' textAlign='center'>
                                <Icon name='mail' color="red" />
                                <Header.Content> DODAJ EMAILA</Header.Content>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={4} textAlign="right" only='large screen'>
                            <Image floated='right' alt="logo" size='big' centered src={LogoTeamLigaGenerator} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Header>
            <Modal.Content >
                <Modal.Description >
                    <Container textAlign="center">
                        {openAddEmail && openAddEmail.open ? <>
                            <Message size='mini' info>
                                <p>
                                    W tym oknie możesz przypisać do wybranego użytkownika adres email.
                                </p>
                                <p><Icon name='info circle' /> <b>Ważna informacja.</b> <b>Podany adres e-mail nie jest dostępny dla innych osób,</b>  nie ma go na żadnej podstronie z wynikami!. Podamy adres e-mail jest widoczny <b>tylko dla organizatora</b> turnieju w celu weryfikacji unikalności danego uczestnika.</p>


                            </Message>
                            Wybrany Uczestnik:
                            <div style={{ overflowX: "auto", width: "100%" }} >
                                <Table size='small' celled striped compact unstackable >
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell >UCZESTNIK</Table.HeaderCell>
                                            {openAddEmail.data.tournament_last ? <Table.HeaderCell >OSTATNI TURNIEJ</Table.HeaderCell> : ""}
                                            {openAddEmail.data.tournament_count ? <Table.HeaderCell >ILOŚĆ TURNIEJÓW</Table.HeaderCell> : ""}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell >{openAddEmail.data.name ? openAddEmail.data.name : openAddEmail.data.member_name ? openAddEmail.data.member_name : "?"}</Table.Cell>
                                            {openAddEmail.data.tournament_last ? <Table.Cell >{openAddEmail.data.tournament_last}</Table.Cell> : ""}
                                            {openAddEmail.data.tournament_count ? <Table.Cell >{openAddEmail.data.tournament_count}</Table.Cell> : ""}
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                            <Header as='h3' icon>
                                <Icon name='mail' />
                                WPROWADŹ ADRES EMAIL
                                <Header.Subheader>
                                    Do powyższego uczestnika zostanie dopisany adres email
                                </Header.Subheader>
                            </Header>
                            {openAddEmail.data.member_id === null ? <Message size='mini' error>
                                Powyższemu uczestnikowi nie ma możliwości dodać adres email.<br />
                                Zapraszamy do kontaktu w celu wyjaśnienia sytuacji.
                            </Message> :
                                <Form size='tiny' onSubmit={addUserEmail}>

                                    <Form.Field
                                        control={Input}
                                        value={emailData}
                                        onChange={(event, data) => setemailData(data.value)}
                                        label='E-mail'
                                        required={true}
                                        placeholder='E-mail'
                                        type='email'
                                    />

                                    <Button color={emailData ? "green" : "grey"} loading={loading} disabled={loading} fluid type='submit'>DODAJ EMAIL</Button>
                                </Form>
                            }
                        </> : ""}
                    </Container>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' onClick={() => closeModal()}>
                    ZAMKNIJ
                </Button>

            </Modal.Actions>
        </Modal>
    )
}

export default AddEmailForMember;