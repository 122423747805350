import React, { useEffect, useContext } from 'react';
import { Icon } from 'semantic-ui-react'

import AuthContext from '../../../context/authContext'
import ShowScoreDetailsView from './ShowScoreDetailsView';
import Marquee from 'react-fast-marquee';

function ShowScoreDetails({ match_detailstmp, systemId, matche_info, refreshView , fontSize }) {
    const authContext = useContext(AuthContext);

    // zrobiło się z tego małe proxy
    useEffect(() => {
        if (Number(process.env.REACT_APP_API_DEBUG) === 1) {
            //  console.log("matche_info", matche_info)
            //    console.log("match_detailstmp", match_detailstmp)
            //    console.log("systemId", match_detailstmp)
        }
        return () => {
        }; // use effect cleanup to set flag false, if unmounted
    }, [match_detailstmp, systemId, matche_info, refreshView])// eslint-disable-line react-hooks/exhaustive-deps


    function checkIsLive() {
        if (matche_info && matche_info.member1_score === null && matche_info.member2_score === null) {
            return true
        }
        return false
    }

    function showScoreRoot() {
        let retView = <Icon title="WYNIKI SZCZEGÓŁOWE" color='grey' name="eye" className='cursorPointer hiddenPrint' style={{ marginTop: 2 }} />
        try {
            if (match_detailstmp && match_detailstmp.match_details && JSON.parse(match_detailstmp.match_details).score && JSON.parse(match_detailstmp.match_details).score.length > 0) {
                let tmparr2 = []
                let cc = 0
                if (match_detailstmp.reverse) {
                    JSON.parse(match_detailstmp.match_details).score.forEach((row1) => {
                        tmparr2.push(" " + row1[match_detailstmp.match_member2_id] + ":" + row1[match_detailstmp.match_member1_id])
                        cc++
                    })
                    
                } else {
                    JSON.parse(match_detailstmp.match_details).score.forEach((row1) => {
                        //  tmparr2.push(" " + row1[match_detailstmp.match_member2_id] + ":" + row1[match_detailstmp.match_member1_id])
                        tmparr2.push(" " + row1[match_detailstmp.match_member1_id] + ":" + row1[match_detailstmp.match_member2_id])
                        cc++
                    })
                   
                   
                }
                if(cc>10){
                    return retView
                }else if(cc>3){
                    return <Marquee loop={0} style={{width:65,margin:"0 auto"}} speed={12} gradientWidth={0}  >
                        <span style={{ fontSize: fontSize? fontSize :  10,marginRight:5 }}>[{tmparr2.toString()}]</span></Marquee>
                }else{
                    return <><span style={{ fontSize: fontSize? fontSize : 10 }}>{tmparr2.toString()}</span></>
                }

            } else {
                return retView
            }


        } catch {
            return retView
        }
    }

    return (
        <>
            {match_detailstmp && match_detailstmp.match_details && match_detailstmp.checkboxMD && !match_detailstmp.bracket ? <div onClick={() => authContext.changeModalInfo({ nameModal: "WynikiSzczegolowe", name: "Wyniki szczegółowe", value: { isLiveDuel: checkIsLive(), match_detailstmp, systemId, matche_info, refreshView } })}>
                {checkIsLive() ?
                    <span title="WYNIKI SZCZEGÓŁOWE" className='cursorPointer hiddenPrint blinkmpN2Nbar' style={{ marginTop: 2, fontSize: 10, fontWeight: "bold", color: "red" }}>LIVE</span>
                    :
                    <span className='cursorPointer'>{showScoreRoot()}</span>
                }
            </div> : match_detailstmp && match_detailstmp.bracket ? <ShowScoreDetailsView isLiveDuel={checkIsLive()} match_detailstmp={match_detailstmp} systemId={systemId} matche_info={matche_info} refreshView={refreshView} /> : ""}

        </>
    )
}

export default ShowScoreDetails;



