import React from 'react'
import {  Grid, Image } from 'semantic-ui-react';
import LogoTeamLigaGenerator from '../../../../assets/logotgver1.svg';
import { changePageName } from '../../../../myfunctions';
import styles from './Error404.module.css';
import pucharpatg1 from '../../../../assets/pucharpatg1.png';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

function Error404Page() {
  let pageName = "Ups!"
  const { width, height } = useWindowDimensions();// eslint-disable-line no-unused-vars

  changePageName(pageName)
  setTimeout(() => {
    // authContext.changeView({ myView: "" })
    // history.push("/")

  }, 3000);

  return (
    <>
      <div className="errorS">
        <Grid stackable> 
          <Grid.Row verticalAlign='middle'>
           {width < 1200 ? ""  :
            <Grid.Column width={5}>
              <Image style={{ padding: 10 }} centered rounded size='medium' src={pucharpatg1} alt="logo" />
            </Grid.Column>
            }
            <Grid.Column width={width < 1200 ? 16 : 11}>
              <div className="ui column center aligned">

                <div className={styles.container}>

                  <h1 className={styles.header}>Ups!</h1>
                  <p className={styles.text}>
                    Wygląda na to, że ta strona odpadła z rozgrywek przed pierwszym gwizdkiem.
                  </p>
                  <p className={styles.text}>
                    Właśnie wpadłeś w <strong>404</strong>, jak drużyna, która nie dotarła do własnego meczu!
                  </p>

                  <h2 className={styles.subheader}>Co teraz?</h2>
                  <ul className={styles.list}>
                    <li>Wróć na <a href="/" className={styles.link}>stronę główną</a> i sprawdź, czy nie przegapiłeś swojego turnieju.</li>
                    <li>Sprawdź, czy adres URL nie popełnił faulu.</li>
                    <li>Ewentualnie wyślij do nas sędziego – zobaczymy, co da się zrobić!</li>
                  </ul>

                  <p className={styles.footerText}>
                    Powodzenia i pamiętaj, żeby następnym razem założyć buty z korkami!
                  </p>
                  <Image style={{ padding: 10 }} centered rounded size='medium' src={LogoTeamLigaGenerator} alt="logo" />
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>


      </div>
    
    </>
  );
}

export default Error404Page;